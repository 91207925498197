<template>
    <div class='validationCodeInput'>
        <div>
            <h4 class="text-muted">Ingresa el código de verificación</h4>
        </div>
        <div>
            <input name='code' class='code-input' type="tel" required/>
            <input name='code' class='code-input' type="tel" required/>
            <input name='code' class='code-input' type="tel" required/>
            <input name='code' class='code-input' type="tel" required/>
            <input name='code' class='code-input' type="tel" required/>
        </div>
    </div>
</template>
<script>

export default {
    components: {},
    props: [
        'showResults',
    ],
    data() {
        return {};
    },
    mounted: function () {

        const self = this;

        const inputElements = [...document.querySelectorAll('input.code-input')]

        inputElements.forEach((ele, index) => {

            ele.value = "";

            ele.addEventListener('keydown', (e) => {
                // if the keycode is backspace & the current field is empty
                // focus the input before the current. Then the event happens
                // which will clear the "before" input box.
                if (e.keyCode === 8 && e.target.value === '') inputElements[Math.max(0, index - 1)].focus()
            })
            ele.addEventListener('input', (e) => {
                const [first, ...rest] = e.target.value
                e.target.value = first ?? '' // first will be undefined when backspace was entered, so set the input to ""
                const lastInputBox = index === inputElements.length - 1
                const insertedContent = first !== undefined
                if (insertedContent && !lastInputBox) {
                    // continue to input the rest of the string
                    inputElements[index + 1].focus()
                    inputElements[index + 1].value = rest.join('')
                    inputElements[index + 1].dispatchEvent(new Event('input'))
                }

                const code = inputElements
                    .filter(({name}) => name)
                    .map(({value}) => value)
                    .join('');

                this.$emit('inputCode', code);
            })
        });
    },
    watch: {},
    methods: {}
};
</script>
