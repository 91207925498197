<template>
    <div id="loader" v-if="coreGetPreloaderStatus">
        <div class="position-relative text-center">
            <img src="staticAssets/PayGuardian.png" alt="cargando" class="logo"/><br>
            <img src="staticAssets/pygwloading.svg" alt="icon" class="movement"/>
        </div>
    </div>
    <router-view/>
</template>

<script>

import {mapGetters, mapMutations} from "vuex";
import Numbro from 'numbro';
import CurrencyLanguajes from 'src/core/CurrencyLanguajes';
import {store} from "src/store";
import BaseScript from 'src/assets/js/base.js';
import {CONFIG} from "src/config";
import Bootstrap from "src/assets/js/lib/bootstrap5";
import Splide from "src/assets/js/lib/splide";

export default {
    name: "App",
    data() {
        return {
            time: 100,
            show: 1,
        };
    },
    mounted() {
        //this.loading(true);
        Bootstrap.load();
        Splide.load();
        BaseScript.loadSite();

        // Numbro
        Numbro.registerLanguage(CurrencyLanguajes.getDefaultLang());
        Numbro.setLanguage('es-GT');

        this.addToHome(2000, 'once');
        this.registerServiceWorker();

        //
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl)
        })
    },
    computed: {
        ...mapGetters({
            coreGetPreloaderStatus: 'coreGetPreloaderStatus',
        })
    },
    methods: {
        ...mapMutations(["loading"]),
        iosAddtoHome() {
            var modal = new bootstrap.Modal(document.getElementById('ios-add-to-home-screen'))
            modal.toggle()
        },
        androidAddtoHome() {
            var modal = new bootstrap.Modal(document.getElementById('android-add-to-home-screen'))
            modal.toggle()
        },
        addToHome(time, once) {

            // Add to Home
            var osDetection = navigator.userAgent || navigator.vendor || window.opera;
            var windowsPhoneDetection = /windows phone/i.test(osDetection);
            var androidDetection = /android/i.test(osDetection);
            var iosDetection = /iPad|iPhone|iPod/.test(osDetection) && !window.MSStream;

            if (once) {
                var AddHomeStatus = localStorage.getItem("FinappAddtoHome");
                if (AddHomeStatus === "1" || AddHomeStatus === 1) {
                    // already showed up
                }
                else {
                    localStorage.setItem("FinappAddtoHome", 1)
                    window.addEventListener('load', () => {
                        if (navigator.standalone) {
                            // if app installed ios home screen
                        }
                        else if (matchMedia('(display-mode: standalone)').matches) {
                            // if app installed android home screen
                        }
                        else {
                            // if app is not installed
                            if (androidDetection) {
                                setTimeout(() => {
                                    androidAddtoHome()
                                }, time);
                            }
                            if (iosDetection) {
                                setTimeout(() => {
                                    iosAddtoHome()
                                }, time);
                            }
                        }
                    });
                }
            }
            else {
                window.addEventListener('load', () => {
                    if (navigator.standalone) {
                        // app loaded to ios
                    }
                    else if (matchMedia('(display-mode: standalone)').matches) {
                        // app loaded to android
                    }
                    else {
                        // app not loaded
                        if (androidDetection) {
                            setTimeout(() => {
                                androidAddtoHome()
                            }, time);
                        }
                        if (iosDetection) {
                            setTimeout(() => {
                                iosAddtoHome()
                            }, time);
                        }
                    }
                });
            }


            //let root = document.documentElement;
            /*root.style.setProperty('--primary-color', store.getters.whiteLabelInfo.primaryColor);
            root.style.setProperty('--secondary-color', store.getters.whiteLabelInfo.secondaryColor);
            root.style.setProperty('--third-color', store.getters.whiteLabelInfo.thirdColor);

            // change favicon
            let link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = store.getters.whiteLabelInfo.faviconUrl;*/

            // change title
            //document.title = store.getters.whiteLabelInfo.nombre;
        },
        registerServiceWorker() {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.register('__service-worker.js')
                    .then(reg => console.log('service worker registered'))
                    .catch(err => console.log('service worker not registered - there is an error.', err));
            }

            // one signal
            if (navigator.serviceWorker) {
                navigator.serviceWorker.register('OneSignalSDKWorker.js')
                    .then(reg => console.log('Onesignal service worker registered'))
                    .catch(err => console.log('Onesignal service worker not registered - there is an error.', err));


                window.OneSignal = window.OneSignal || [];
                OneSignal.push(function() {
                    OneSignal.init({
                        appId: CONFIG.oneSignalApp,
                        safari_web_id: "web.onesignal.auto.13a94bce-1224-4d5f-912e-16820eddb8b3",
                        notifyButton: {
                            enable: false,
                        },
                        autoResubscribe: true,
                    });
                    //OneSignal.showNativePrompt();
                });
            }
        },

    }
};
</script>
