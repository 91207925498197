<template>
    <layout :active="1" :title="true">

        <!-- App Header -->
        <div class="appHeader">
            <div class="left">
                <a @click="$router.back()" class="headerButton goBack">
                    <i class="fa-solid fa-chevron-left"></i>
                </a>
            </div>
            <div class="pageTitle">
                Dispositivos registrados
            </div>
        </div>
        <!-- * App Header -->

        <!-- Add Card Action Sheet -->
<!--        <div class="modal fade action-sheet" id="addCardActionSheet" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Agregar método de pago</h5>
                    </div>
                    <div class="modal-body">
                        <div class="action-sheet-content">

                        </div>
                    </div>
                </div>
            </div>
        </div>-->
        <!-- * Add Card Action Sheet -->

        <!-- App Capsule -->
        <div id="appCapsule">

            <div class="section mt-2 mb-2">

                <div class="goals">
                    <!-- item -->
                    <div v-for="item in devicesList" class="item">
                        <div class="in">
                            <div class="w-100">
                                <h4>
                                    <i v-if="item.type === 'phone'" class="fa-solid fa-mobile-phone"></i>
                                    <i v-if="item.type === 'desktop'" class="fa-solid fa-laptop"></i>
                                    <span class="ms-2">
                                        {{item.name}}
                                    </span>
                                    <div class="float-end dropdown">
                                        <button type="button" class="btn btn-link btn-icon" data-bs-toggle="dropdown">
                                            <i class="fa-solid fa-ellipsis-h"></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-end">
                                            <a class="dropdown-item" @click="deleteDevice(item.id)">
                                                <i  class="fa-solid fa-trash"></i>&nbsp; Eliminar
                                            </a>
                                        </div>
                                    </div>
                                </h4>
                                <div class="mt-2">
                                    <p>
                                        <span v-if="item.current" class="text-success"> Dispositivo actual</span>
                                    </p>
                                    <p><span class="text-primary">Última actividad:</span> <b>{{item.lastActivity}}</b></p>
                                    <hr>
                                    <p>
                                        Detalles: {{item.userAgent}}
                                    </p>
                                </div>
<!--                                <div v-if="item.current" class="text-center">
                                    <hr>
                                    <div class="btn btn-primary text-wrap" @click="associateBiometric">
                                        <div class="text-light me-2">Asociar entrada biométrica</div><br>
                                        <div>
                                            <i class="fa-solid fa-fingerprint fa-2x"></i>
                                        </div>
                                    </div>
                                </div>-->
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!-- * App Capsule -->

    </layout>
</template>

<script>
import Layout from "src/modules/dashboard/components/Layout";
import API from "../../../core/Api";
import {CONFIG} from "src/config";
import Tools from "src/core/Tools";
import {mapGetters} from "vuex";

export default {
    name: "Dashboard",
    components: {
        //TimelineChart,
        Layout,
    },
    data() {
        return {
            devicesList: {},
        };
    },
    watch: {
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo',
            AuthIsLogged: 'AuthIsLogged',
            AuthGetToken: 'AuthGetToken',
        })
    },
    mounted() {
        const self = this;
        this.getDevices();
    },
    methods: {
        getDevices() {
            const self = this;
            API.send('GET', 'payguardian/devices/get', {},
            function (response) {
                if (response.status) {
                    self.devicesList = response.data;
                }
            },
            function (response) {
                //self.invalidLink = true;
                API.showErrorAlert(response.msg);
            });
        },
        deleteDevice(idDevice) {
            const self = this;
            
            API.showConfirm('¿Está seguro de eliminar?', '', function () {
                const self = this;
                API.send('DELETE', 'payguardian/devices/delete', {id: idDevice},
                function (response) {
                    if (response.status) {
                        API.showSuccessAlert(response.msg);
                        self.getDevices();
                    }
                    else {
                        API.showErrorAlert(response.msg);
                    }
                },
                function (response) {
                    //self.invalidLink = true;
                    API.showErrorAlert(response.msg);
                });
            })
        },
        async associateBiometric() {
            const self = this;

            const publicKeyCredentialCreationOptions = {
                challenge: Uint8Array.from(
                    self.AuthGetToken, c => c.charCodeAt(0)),
                rp: {
                    name: "PayG Wallet",
                    id: "pygwallet.com",
                },
                user: {
                    id: Uint8Array.from(
                        self.AuthGetUserInfo.idString, c => c.charCodeAt(0)),
                    name: self.AuthGetUserInfo.email,
                    displayName: self.AuthGetUserInfo.nombre,
                },
                pubKeyCredParams: [{alg: -7, type: "public-key"}],
                authenticatorSelection: {
                    authenticatorAttachment: "cross-platform",
                },
                timeout: 60000,
                attestation: "direct"
            };

            /*const credential = await navigator.credentials.create({
                publicKey: publicKeyCredentialCreationOptions
            });*/

            /*API.send('GET', 'payguardian/devices/get', {},
            function (response) {
                if (response.status) {
                    self.devicesList = response.data;
                }
            },
            function (response) {
                //self.invalidLink = true;
                API.showErrorAlert(response.msg);
            });*/
        },
    },
};
</script>
