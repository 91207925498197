<template>
    <div>
        <div class="text-center p-4">
            <button class="btn btn-primary">Iniciar lectura de QR</button>
        </div>
        <div class="qrReaderModal">
            <div class="qrReaderCamera">
                <div id="qrReaderCameraVideo" class="qrReaderCameraVideo">
                    <video id="qr-video"></video>
                </div>
            </div>
            <div class="qrReaderModalContainer">
                <div class="cameraControls">
                    <!--<select id="inversion-mode-select">
                        <option value="original">Normal</option>
                        <option value="invert">Fondo obscuro QR Claro</option>
                        <option value="both">Ambos</option>
                    </select>-->
                    <div class="cameraControl" @click="closeScanner">
                        <i class="fa-solid fa-circle-xmark text-danger"></i>
                    </div>
                    <div class="cameraControl" @click="rotateCamera">
                        <i v-bind:class="{'fa-solid fa-camera-rotate text-success': hasCamera, 'fa-solid fa-camera-rotate text-danger': !hasCamera}"></i>
                    </div>
                    <div class="cameraControl" v-if="hasFlash" @click="enabledFlash">
                        <i v-bind:class="{'fa-solid fa-bolt text-warning': enabledFlash, 'fa-solid fa-bolt text-muted': !enabledFlash}"></i>
                    </div>
                    <div class="cameraControl" @click="stopScanner">
                        <i class="fa-solid fa-pause-circle text-primary"></i>
                    </div>
                    <div class="cameraControl" @click="startScanner">
                        <i class="fa-solid fa-play-circle text-primary"></i>
                    </div>
                    <div v-if="scanned" class="cameraControl" @click="resetScanner">
                        <i class="fa-solid fa-arrow-rotate-back text-warning"></i>
                    </div>
                </div>
                <div class="mt-3 text-center">
                    <hr>
                    <div v-if="scanned">
                        <div class="text-success mb-3"><i class="fa-solid fa-check-circle"></i> Escaneo correcto</div>
                        <div v-if="showResults" class="scannerResult">
                            {{scannerResult}}
                        </div>
                        <div>
                            <slot></slot>
                        </div>
                    </div>
                    <div v-else class="scannerQrInstructions">
                        <div class="text-muted font-weight-bold">
                            Escaneando QR
                        </div>
                        <div class="text-muted">
                            Encuadre el código entre los marcadores
                        </div>
                        <div>
                            <img src="staticAssets/qrScanner/qrscan.jpg">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

import QrScanner from 'qr-scanner';

export default {
    components: {

    },
    props: [
        'showResults',
    ],
    data() {
        return {
            scanned: false,
            scanner: false,
            enabledCamera: 0,
            cameras: {},
            hasCamera: false,
            hasFlash: false,
            enabledFlash: false,
            scannerResult: "",
            scannerResultError: "",
            videoElement: false,
            qrReaderCameraVideo: false,
        };
    },
    mounted: function () {

        const self = this;
        self.videoElement = document.getElementById('qr-video');
        self.qrReaderCameraVideo = document.getElementById('qrReaderCameraVideo');

        this.scanner = new QrScanner(self.videoElement, result => self.setResult(result), {
            onDecodeError: error => {
                if (!self.scanned) {
                    self.scannerResultError = error;
                }
            },
            highlightScanRegion: true,
            highlightCodeOutline: true,
            /*calculateScanRegion: function (){
                return {
                    x: '50%',
                    y: '50%',
                    width: 400,
                    height: 400,
                }
            }*/
        });

        this.scanner.start().then(() => {
            QrScanner.listCameras(true).then(function (cameras) {
                self.cameras = cameras;
            });
            self.refreshFlash();
            self.fixHeight();
        });

        QrScanner.hasCamera().then(hasCamera => {self.hasCamera = hasCamera});

        // for debugging
        window.scanner = this.scanner;


        /*this.scanned = true;
        this.scannerResult = 'https://app.jirafazul.com/frm/assistance/validate/794e710eed36ff5ed950c358df23d3f6019f95d470dee2f3b71d7ab58256f59fb88939658a53892bb5b1f02472';
        this.$emit('qrScanned', this.scannerResult);*/
    },
    watch: {
        /*editorValue: function (val) {

        },*/
    },
    methods: {
        rotateCamera() {
            const self = this;
            const nextCameraNumber = parseInt(this.enabledCamera) + 1;
            const nextCameraIndex = (typeof this.cameras[nextCameraNumber] !== 'undefined') ? nextCameraNumber : 0;
            this.enabledCamera = nextCameraIndex;
            this.scanner.setCamera(this.cameras[nextCameraIndex].id).then(function (){
                self.refreshFlash();
                self.fixHeight();
            });

        },
        startScanner() {
            const self = this;
            this.scanner.start().then(function (){
                self.fixHeight();
            });
        },
        fixHeight() {
            const videoHeightContainer = this.qrReaderCameraVideo.offsetHeight;
            const videoHeight = this.videoElement.offsetHeight;

            let topFix = 0;

            if (videoHeightContainer > videoHeight) {
                topFix = ((videoHeightContainer - videoHeight) / 2);
            }
            else {
                topFix = ((videoHeight - videoHeightContainer) / 2) * -1;
            }
            document.getElementById('qrReaderCameraVideo').style.top = topFix+'px';
        },
        stopScanner() {
            this.scanner.stop();
        },
        refreshFlash() {
            const self = this;
            this.scanner.hasFlash().then(hasFlash => {
                console.log(hasFlash);
                self.hasFlash = hasFlash;
            });
        },
        toogleFlash() {
            this.scanner.toggleFlash().then(() => this.enabledFlash = this.scanner.isFlashOn());
        },
        setResult(result) {
            if (typeof result.data !== 'undefined' && !this.scanned) {
                this.scannerResultError = "";
                this.scannerResult = result.data;
                this.scanned = true;
                this.$emit('qrScanned', this.scannerResult);
                this.closeScanner();
            }
        },
        resetScanner() {
            this.scannerResultError = "";
            this.scannerResult = '';
            this.scanned = false;
        },
        closeScanner() {
            this.resetScanner();
            this.stopScanner();
            this.$emit('readerStop');
        },
    }
};
</script>
