import Configuration from "./views/Configuration";
import Devices from "./views/Devices";

export default [
    {
        path: "/configuration",
        component: Configuration,
    },
    {
        path: "/devices",
        component: Devices,
    },

];
