// Core state
import { createStore } from 'vuex'

import {authState} from "src/modules/auth/state";
import localDisk from "src/localDisk";
//import {orderState} from "src/modules/order/state";

export const store = createStore({
    state: {
        preloader: false,
        fp: localDisk.read('fp'),
    },
    mutations: {
        loading(state, data) {
            state.preloader = data;
        },
        SetDeviceFingerprint(state, data) {
            if ( data ) {
                state.fp = data
                localDisk.write('fp', data)
            }
            else {
                localDisk.write('fp', null);
            }
        },
    },
    actions: {
        coreShowLoading: ({commit}) => {
            commit('loading', true);
        },
        coreHideLoading: ({commit}) => {

            // 300 ms para esperar cualquier otro loading
            setTimeout(function (){
                commit('loading', false);
            }, 300);
        },
        SetDeviceFingerprint: ({commit}, payload) => {
            commit('SetDeviceFingerprint', payload);
        },

    },
    getters: {
        coreGetPreloaderStatus: state => {
            return state.preloader;
        },
        GetDeviceFingerprint: state => {
            return state.fp;
        },
    },
    // cargo los modulos
    modules: {
        auth: authState,
        //order: orderState,
    },
})
