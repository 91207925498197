import {CONFIG} from 'src/config';

export default {
    write: (key, data, forceDisk) => {
        const disk = (forceDisk) ? forceDisk : CONFIG.localStorageDisk;
        const tmp = localStorage.getItem(disk);
        let objTMP = {};

        if (tmp) {
            try {
                objTMP = JSON.parse(tmp);
                objTMP[key] = data;
            }
            catch (e) {
                objTMP = {};
            }
        }
        // save object with key
        objTMP[key] = data;
        localStorage.setItem(disk, JSON.stringify(objTMP));
    },
    read: (key, forceDisk) => {
        const disk = (forceDisk) ? forceDisk : CONFIG.localStorageDisk;
        const tmp = localStorage.getItem(disk);
        let objTMP = {};

        if (tmp) {
            try {
                objTMP = JSON.parse(tmp);
                if (typeof objTMP[key] !== 'undefined') {
                    return objTMP[key];
                }
            }
            catch (e) {
                return null;
            }
        }
        else {
            return null;
        }
    },
    clearDisk: (forceDisk) => {
        const disk = (forceDisk) ? forceDisk : CONFIG.localStorageDisk;
        localStorage.setItem(disk, null);
    }
}
