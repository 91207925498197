<template>
    <layout :active="1" :title="true">

        <!-- App Header -->
        <div class="appHeader">
            <div class="left">
                <a @click="$router.back()" class="headerButton goBack">
                    <i class="fa-solid fa-chevron-left"></i>
                </a>
            </div>
            <div class="pageTitle">
                Mi billetera
            </div>
            <div class="right">
                <a ref="addItemToWalletBtn" class="headerButton" @click="startModal">
                    <i class="fa-solid fa-plus"></i>
                </a>
            </div>
        </div>
        <!-- * App Header -->

        <!-- Add Card Action Sheet -->
        <div class="modal fade action-sheet" id="addItemToWallet" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Agregar item a mi billetera</h5>
                        <i class="fa-solid fa-times-circle closeModal" @click="closeModal"></i>
                    </div>
                    <div class="modal-body">
                        <div class="action-sheet-content">
                            <div v-if="scannedInfoDetail.token">
                                <h3>{{ scannedInfoDetail.itemName }}</h3>
                                <div class="my-4 text-center">
                                    <img :src="scannedInfoDetail.imagen" style="max-width: 300px">
                                </div>
                                <div>
                                    <b class="mr-2">Código:</b> {{ scannedInfoDetail.codigoItem }}
                                </div>
                                <div>
                                    <b class="mr-2">Adquirido por:</b> {{ scannedInfoDetail.comprador }}
                                </div>
                            </div>
                            <div v-else>
                                <div>
                                    <h4>
                                        Escaneo de código QR
                                    </h4>
                                    <div class="text-muted">
                                        Puedes escanear el código QR del item que deseas agregar a tu billetera
                                    </div>
                                    <div class="text-center my-4">
                                        <button class="btn btn-success" @click="startQrReader = 1">
                                            <i class="fa-solid fa-qrcode me-2"></i>
                                            Iniciar lector de QR
                                        </button>
                                    </div>
                                </div>
                                <!--    <div>
                                    <hr>
                                    <div class="text-primary">Si no tienes QR, puedes ingresar el código PayGW manualmente en el campo a continuación:</div>
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <input type="tel" @input="changeNumber" class="form-control" placeholder="Escribe aquí el código PayGW" :value="addMethodNumber" :maxlength="cardNumberMaxLength" data-card-field autocomplete="off"/>
                                        </div>
                                    </div>

                                </div>-->
                                <div class="text-muted mt-2 cursor-pointer text-warning" @click="codigoPayWQuestion">
                                    ¿Qué es un código PayGW? <i class="fa-solid fa-question-circle"></i>
                                </div>
                            </div>
                            <div class="form-group basic mt-2">
                                <button v-if="scannedInfoDetail.token" type="button" class="btn btn-success btn-block mb-1" @click="startQrReader = 1">Volver a escanear</button>
                                <button type="button" class="btn btn-primary btn-block" @click="addToWallet">Agregar a billetera</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * Add Card Action Sheet -->

        <!-- Add Card Action Sheet -->
        <div class="modal fade action-sheet" id="verifyCodeItem" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Verificación de identidad</h5>
                        <i class="fa-solid fa-times-circle closeModal" @click="closeVerifyModal"></i>
                    </div>
                    <div class="modal-body">
                        <div class="action-sheet-content text-justify">
                            <h4>
                                Se enviará un código de verificación al correo electrónico utilizado para la compra de este item
                            </h4>
                            <div class="my-5 text-center">
                                <a v-if="!counterTimeOut" class="btn btn-success btn-lg" @click="sendVerificationCode">
                                    <i class="fa-solid fa-envelope me-1"></i>
                                    Enviar código
                                </a>
                                <div v-else>
                                    <h4 class="text-danger">Reenviar código: <span>{{TimeCount}}</span></h4>
                                </div>
                            </div>
                            <div class="mt-4">
                                <div>
                                    <verification-code v-if="verifyItem.id" @input-code="inputVerificationCode"></verification-code>
                                </div>
                            </div>
                            <div class="form-group basic mt-5">
                                <button type="button" class="btn btn-primary btn-block btn-lg" @click="verifyCode">Verificar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * Add Card Action Sheet -->

        <!-- Add Card Action Sheet -->
        <div class="modal fade action-sheet" id="itemDetail" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{itemDetailView.itemName}}</h5>
                        <i class="fa-solid fa-times-circle closeModal" @click="closeItemDetailModal"></i>
                    </div>
                    <div class="modal-body">
                        <div class="action-sheet-content text-justify">
                            <div>
                                <span class="fw-bold text-primary">Código:</span> {{itemDetailView.code}}
                            </div>
                            <div>
                                <span class="fw-bold text-primary">Comprado por:</span> {{itemDetailView.buyerName}}
                            </div>
                            <div class="text-center mb-3 mt-2">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="mb-1">QR / Identificación</div>
                                        <qrcode-vue :value="itemDetailView.assistanceToken" size="150" level="M" />
                                    </div>
                                    <div class="col-6 pt-5">
                                        <div class="mt-4 btn btn-primary d-block py-2" @click="$router.push('/dashboard#payqr')" style="height: auto">
                                            <div>
                                                <i class="fa-solid fa-credit-card-alt fa-1x me-1"></i> Generar código de pago
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="text-center my-2">

                            </div>
                            <hr>
                            <div class="mt-4 text-center">
                                <img :src="itemDetailView.externalImage" alt="img" class="image-block imaged" style="max-height: 30vh; max-width: 100%">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * Add Card Action Sheet -->

        <!-- App Capsule -->
        <div id="appCapsule">

            <qr-reader v-if="startQrReader" @qr-scanned="qrScanned" @reader-stop="startQrReader = 0">
                <div class="mt-5">
                    <div v-if="scannedInfoError !== ''">
                        {{ scannedInfoError }}
                    </div>
                </div>
            </qr-reader>

            <div v-if="Object.keys(walletItems).length > 0" class="section mt-2">
                <div class="transactions">
                    <a v-for="item in walletItems" class="item position-relative">
                        <div class="detail w-100">
                            <img :src="item.externalImage" alt="img" class="image-block imaged w-50">
                            <div class="w-75">
                                <div class="font-weight-bold mb-2">
                                    {{ item.itemName }}
                                </div>
                                <p>
                                    Código: {{item.code}}
                                </p>
                                <p>
                                    {{item.buyerName}}
                                </p>
                                <div v-if="!item.verified">
                                    <p class="text-danger mt-1"><i class="fa-solid fa-warning me-1"></i>Compra no verificada</p>
                                    <div class="mt-2 text-center">
                                        <div class="btn btn-primary btn-block" @click="openVerifyModal(item)">
                                            <i class="fa-solid fa-check-circle"></i>
                                            Verificar ahora
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <p class="text-success mt-1"><i class="fa-solid fa-check-circle me-1"></i>Compra verificada</p>
                                </div>
                                <div class="w-100 text-center">
                                    <hr>
                                    <a @click="openItemDetailModal(item)" class="cursor-pointer opacity-hover" style="right: 1em; top: 0.5em">
                                        <i class="fa-solid fa-eye"></i> Ver detalles
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!--<div class="right">
                            <div class="price text-danger"> - $ 150</div>
                        </div>-->

                    </a>
                </div>
            </div>

            <!-- * card block -->
            <div v-if="Object.keys(walletItems).length === 0" class="text-center text-muted p-4">
                La billetera está vacía
                <div class="mt-2" @click="getItems">
                    <i class="fa-solid fa-refresh cursor-pointer"></i> &nbsp; Refrescar
                </div>
            </div>
        </div>
        <!-- * App Capsule -->

    </layout>
</template>

<script>
import Layout from "src/modules/dashboard/components/Layout";
import payCard from "src/modules/wallet/components/Card";
import API from "../../../core/Api";
import {CONFIG} from "src/config";
import Tools from "src/core/Tools";
import QrReader from "src/components/QrReader";
import VerificationCode from "src/components/VerificationCode";
import QrcodeVue from "qrcode.vue";

export default {
    name: "Dashboard",
    components: {
        VerificationCode,
        QrReader,
        //TimelineChart,
        Layout,
        payCard,
        QrcodeVue
        //Toster,
    },
    data() {
        return {
            walletItems: {},
            startQrReader: 0,
            scannedData: '',
            scannedInfoDetail: {},
            scannedInfoError: "",

            //verificación
            verifyItem: {},
            counterTimeOut: false,
            TimeCount: 0,
            verificationCode: '',

            //item detail
            itemDetailView: {},

            // magnet
            addMagnetToken: '',
        };
    },
    watch: {},
    computed: {},
    mounted() {
        const self = this;
        this.getItems();

        // verify to add
        if (typeof this.$route.params.magnetToken !== 'undefined') {
            console.log(this.$route.params.magnetToken);
            this.addMagnetToken = this.$route.params.magnetToken;

            setTimeout(function () {
                self.$refs.addItemToWalletBtn.click();
                self.qrScanned(self.$route.params.magnetToken);
            }, 500);
        }

        setTimeout(function () {
            if (window.location.hash === '#add') {
                self.$refs.addItemToWalletBtn.click();
            }
        }, 500);
    },
    methods: {
        // obtener items
        getItems() {
            const self = this;
            API.send('GET', 'payguardian/wallet/get/item', {},
                function (response) {
                    if (response.status) {
                        self.walletItems = response.data;
                    }
                },
                function (response) {
                    //self.invalidLink = true;
                    API.showErrorAlert(response.msg);
                });
        },

        // escaneo qr
        qrScanned(data) {

            const self = this;
            API.send('POST', 'payguardian/wallet/verify/item', {
                    scanner: data,
                },
                function (response) {
                    if (response.status) {
                        self.scannedData = data;
                        self.scannedInfoDetail = response.data;
                    }
                    else {
                        API.showErrorAlert('Error', response.msg);
                    }
                },
                function (response) {
                    //self.invalidLink = true;
                    API.showErrorAlert('Error', response.msg);
                });
        },
        addToWallet() {
            const self = this;
            API.send('POST', 'payguardian/wallet/add/item', {
                    scanner: self.scannedData,
                },
                function (response) {
                    if (response.status) {
                        API.showSuccessNotify(response.msg);
                        self.closeModal();
                        self.getItems();
                    }
                    else {
                        API.showErrorNotify(response.msg);
                    }
                },
                function (response) {
                    //self.invalidLink = true;
                    API.showErrorNotify(response.msg);
                });
        },
        startModal() {
            Tools.openBoostrapModal('#addItemToWallet')
        },
        closeModal() {
            this.scannedInfoDetail = {};
            this.scannedInfoError = {};
            Tools.closeBoostrapModal('#addItemToWallet')
        },

        // verificación de items
        sendVerificationCode() {
            const self = this;
            API.send('POST', 'payguardian/wallet/item/verify-code/send', {
                    id: self.verifyItem.id,
                },
                function (response) {
                    if (response.status) {
                        API.showSuccessNotify(response.msg);

                        self.TimeCount = 60;
                        const countdown = function () {
                            if(self.TimeCount > 1) {
                                self.TimeCount--;
                                self.counterTimeOut = setTimeout(function() {
                                    countdown()
                                },1000);
                            }
                            else {
                                if (self.counterTimeOut) {
                                    clearTimeout(self.counterTimeOut);
                                    self.counterTimeOut = false;
                                }
                            }
                        }
                        countdown();
                    }
                    else {
                        API.showErrorNotify(response.msg);
                    }
                },
                function (response) {
                    //self.invalidLink = true;
                    API.showErrorNotify(response.msg);
                });
        },
        verifyCode() {
            const self = this;
            API.send('POST', 'payguardian/wallet/item/verify-code/validate', {
                    id: self.verifyItem.id,
                    code: self.verificationCode,
                },
                function (response) {
                    if (response.status) {
                        API.showSuccessNotify(response.msg);
                        self.getItems();
                        self.closeVerifyModal();
                    }
                    else {
                        API.showErrorNotify(response.msg);
                    }
                },
                function (response) {
                    //self.invalidLink = true;
                    API.showErrorNotify(response.msg);
                });
        },
        openVerifyModal(item) {
            this.verifyItem = item;
            Tools.openBoostrapModal('#verifyCodeItem');
        },
        closeVerifyModal() {
            this.verifyItem = {};
            this.verificationCode = '';
            Tools.closeBoostrapModal('#verifyCodeItem')
        },
        inputVerificationCode(data) {
            this.verificationCode = data;
        },

        // item detalle
        //itemDetail
        openItemDetailModal(item) {
            this.itemDetailView = item;
            Tools.openBoostrapModal('#itemDetail');
        },
        closeItemDetailModal() {
            Tools.closeBoostrapModal('#itemDetail')
        },

        // otros
        codigoPayWQuestion() {
            API.showSuccessAlert('Código PayGW', 'El código PayGW identifica a todos los items activos en la red de PayG Wallet, puedes encontrar entradas a eventos, conciertos, productos, suscripciones y mucho más.', 0);
        },

    },
};
</script>
