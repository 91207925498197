<template>
    <layout :active="1" :title="true">

        <!-- App Header -->
        <div class="appHeader">
            <div class="left">
                <a @click="$router.back()" class="headerButton goBack">
                    <i class="fa-solid fa-chevron-left"></i>
                </a>
            </div>
            <div class="pageTitle">
                Configuración
            </div>
        </div>
        <!-- * App Header -->

        <!-- App Capsule -->
        <div id="appCapsule">

            <div class="listview-title mt-1">Notificaciones</div>
            <ul class="listview image-listview text inset">
                <li>
                    <div class="item">
                        <div class="in">
                            <div>
                                Habilitar notificaciones push
                                <div class="text-muted">
                                    Permite recibir notificaciones sobre inicios de sesión, pagos, etc. Compatible con Android, Windows y Mac.<br>
                                    <span class="text-warning">Próximamente compatible con iPhone.</span>
                                </div>
                                <div class="text-center mt-2">
                                    <button class="btn btn-primary me-2" @click="updateNotificationSettings(true);">
                                        <i class="fa-solid fa-bell"></i> &nbsp; Activar
                                    </button>
                                    <button class="btn btn-dark" @click="updateNotificationSettings(false);">
                                        Desactivar
                                    </button>
                                </div>
                            </div>
<!--                            <div class="form-check form-switch  ms-2">
                                <input class="form-check-input" type="checkbox" id="SwitchCheckDefault1" v-model="enableNotifications">
                                <label class="form-check-label" for="SwitchCheckDefault1"></label>
                            </div>-->
                        </div>
                    </div>
                </li>
            </ul>

            <div class="listview-title mt-1">Seguridad</div>
            <ul class="listview image-listview text inset">
                <li @click="$router.push('/devices')" class="cursor-pointer">
                    <a class="item">
                        <div class="in">
                            <div>Dispositivos registrados</div>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="#" class="item">
                        <div class="in">
                            <div>Contraseña</div>
                        </div>
                    </a>
                </li>
<!--                <li>
                    <a href="#" class="item">
                        <div class="in">
                            <div>Update E-mail</div>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="#" class="item">
                        <div class="in">
                            <div>Address</div>
                            <span class="text-primary">Edit</span>
                        </div>
                    </a>
                </li>
                <li>
                    <div class="item">
                        <div class="in">
                            <div>
                                Private Profile
                            </div>
                            <div class="form-check form-switch ms-2">
                                <input class="form-check-input" type="checkbox" id="SwitchCheckDefault2">
                                <label class="form-check-label" for="SwitchCheckDefault2"></label>
                            </div>
                        </div>
                    </div>
                </li>-->
            </ul>

        </div>

        <!--        <div class="listview-title mt-1">Profile Settings</div>
                <ul class="listview image-listview text inset">
                    <li>
                        <a href="#" class="item">
                            <div class="in">
                                <div>Change Username</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item">
                            <div class="in">
                                <div>Update E-mail</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item">
                            <div class="in">
                                <div>Address</div>
                                <span class="text-primary">Edit</span>
                            </div>
                        </a>
                    </li>
                    <li>
                        <div class="item">
                            <div class="in">
                                <div>
                                    Private Profile
                                </div>
                                <div class="form-check form-switch ms-2">
                                    <input class="form-check-input" type="checkbox" id="SwitchCheckDefault2">
                                    <label class="form-check-label" for="SwitchCheckDefault2"></label>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>

                <div class="listview-title mt-1">Security</div>
                <ul class="listview image-listview text mb-2 inset">
                    <li>
                        <a href="#" class="item">
                            <div class="in">
                                <div>Update Password</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <div class="item">
                            <div class="in">
                                <div>
                                    2 Step Verification
                                </div>
                                <div class="form-check form-switch ms-2">
                                    <input class="form-check-input" type="checkbox" id="SwitchCheckDefault3" checked/>
                                    <label class="form-check-label" for="SwitchCheckDefault3"></label>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <a href="#" class="item">
                            <div class="in">
                                <div>Log out all devices</div>
                            </div>
                        </a>
                    </li>
                </ul>-->
    </layout>
</template>

<script>
import Layout from "src/modules/dashboard/components/Layout";
import API from "../../../core/Api";
import {mapGetters} from "vuex";
import {store} from "src/store";

export default {
    name: "Dashboard",
    components: {
        //TimelineChart,
        Layout,
        //Toster,
    },
    data() {
        return {
            // Notificaciones
            serverPushId: false,
            enableNotifications: false,

        };
    },
    watch: {
        /*enableNotifications: function (value) {
            this.updateNotificationSettings(value);
        }*/
    },
    mounted() {
        // notificaciones
        this.startNotifications();
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo',
            AuthIsLogged: 'AuthIsLogged',
        })
    },
    methods: {
        updateNotificationSettings(enableSubscriptions) {
            const self = this;

            const saveNotificationStatus = function (status) {

                //console.log(status);

                const saveInDb = function (idOneSignal) {

                    store.dispatch('coreShowLoading');

                    // send id to system
                    setTimeout(function () {
                        API.send('POST', 'payguardian/notifications/associate/push/id', {id: idOneSignal},
                            function (response) {
                                if (!response.status) {
                                    API.showSuccessNotify(response.msg);
                                }
                                else {
                                    // si estaba desactivando
                                    if (!idOneSignal) {
                                        API.showSuccessNotify("Alertas desactivadas");
                                    }
                                    self.serverPushId = true;
                                }
                                store.dispatch('coreHideLoading');
                            },
                            function (response) {
                                //self.invalidLink = true;
                                store.dispatch('coreHideLoading');
                                API.showErrorAlert(response.msg);
                            }, {
                                disableLoading: true
                            });
                    }, 1000);
                }

                if (status) {
                    // envío email
                    OneSignal.getUserId(function (userId) {
                        if (userId) {
                            saveInDb(userId);
                        }
                        else {
                            saveInDb(null);
                        }
                    });
                }
                else {
                    saveInDb(null);
                }
            }

            this.getNotificationsState().then(function (state) {

                //console.log(enableSubscriptions);

                // si quiere habilitarlas
                if (enableSubscriptions) {
                    if (state.isOptedOut) {
                        /* Opted out, opt them back in */
                        OneSignal.setSubscription(true);
                        saveNotificationStatus(true);
                    }
                    else {
                        /* Unsubscribed, subscribe them */
                        OneSignal.registerForPushNotifications();
                        saveNotificationStatus(true);
                    }
                }
                else {
                    // Si están habilitadas en onesignal, lo saco
                    if (state.isPushEnabled) {
                        /* Subscribed, opt them out */
                        OneSignal.setSubscription(false);
                    }
                    saveNotificationStatus(false);
                }
            });
        },
        getNotificationsState() {
            return Promise.all([
                OneSignal.isPushNotificationsEnabled(),
                OneSignal.isOptedOut()
            ]).then(function (result) {
                const isPushEnabled = result[0];
                const isOptedOut = result[1];
                return {
                    isPushEnabled: isPushEnabled,
                    isOptedOut: isOptedOut
                };
            });
        },
        startNotifications() {
            const self = this;
            self.serverPushId = self.AuthGetUserInfo.pushId;

            const changeSubscriptionsStatus = function (isSubscribed) {
                self.getNotificationsState().then(function (state) {
                    if (state.isPushEnabled && self.serverPushId) {
                        self.enableNotifications = true;
                    }
                    else {
                        self.enableNotifications = false;
                    }
                });
            }

            OneSignal.push(function () {
                // If we're on an unsupported browser, do nothing
                if (!OneSignal.isPushNotificationsSupported()) {
                    return;
                }
                // evento para cambio de suscripción
                OneSignal.on("subscriptionChange", function (isSubscribed) {
                    /* If the user's subscription state changes during the page's session, update the button text */
                    changeSubscriptionsStatus();
                });
                changeSubscriptionsStatus();
            });
        }
    },
};
</script>
