import {CONFIG} from 'src/config';
import { store } from '../store'
import Swal from "sweetalert2";

export default {
    send: (method, url, data, onSuccess, onError, options) => {

        if (!method) method = 'GET';
        method = method.toUpperCase();

        if (!options) options = {};

        if (!options.disableLoading) {
            // voy a validar si el token esta ok
            store.dispatch('coreShowLoading');
        }

        const config = {
            method: method,
            headers: new Headers({
                'Authorization': 'Bearer '+store.getters.AuthGetToken,
                "Content-Type": "application/json",
                "x-pg-fp": (store.getters.GetDeviceFingerprint.f) ? store.getters.GetDeviceFingerprint.f : null,
                "x-pg-n": (store.getters.GetDeviceFingerprint.n) ? store.getters.GetDeviceFingerprint.n : null,
                "x-pg-ua": (store.getters.GetDeviceFingerprint.ua) ? store.getters.GetDeviceFingerprint.ua : null,
            }),
        };

        if (method !== 'GET') {
            config['body'] = JSON.stringify(data);
        }

        fetch(CONFIG.getWsUrl(url), config)
            .then(response => response.json())
            .then(data => {
                if (typeof data.status !== 'undefined') {
                    if (data.status) {
                        onSuccess(data);
                        if (!options.disableLoading) {
                            store.dispatch('coreHideLoading');
                        }
                    }
                    else {
                        if (!options.disableLoading) {
                            store.dispatch('coreHideLoading');
                        }
                        onError(data);
                    }
                }
            })
            .catch((e) => {
                console.log('API-RequestError:'+ e);
                if (!options.disableLoading) {
                    store.dispatch('coreHideLoading');
                }
                Swal.fire({
                    title: 'Error!',
                    text: 'Ha ocurrido un error, por favor intente de nuevo',
                    toast: true,
                    icon: 'error',
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 4000
                })
            });
    },
    showSuccessAlert : (msg, text, time) => {
        Swal.fire({
            icon: 'success',
            text: (text) ? text : '',
            title: msg,
            showConfirmButton: true,
            confirmButtonText: 'Cerrar',
            timer: (time === 0) ? 999999 : ((time > 0) ? time : 3500)
        })
    },
    showErrorAlert : (msg, text, time) => {
        Swal.fire({
            icon: 'error',
            title: msg,
            text: (text) ? text : '',
            showConfirmButton: true,
            confirmButtonText: 'Cerrar',
            timer: (time === 0) ? 999999 : ((time > 0) ? time : 3500)
        })
    },
    showFormValidationErrors : (errors) => {
        let strError = '';
        for (let error of errors) {
            strError += error + ', ';
        }
        strError = strError.replace(/,\s*$/, "");

        Swal.fire({
            title: '',
            text: strError,
            toast: true,
            icon: 'error',
            position: 'top-end',
            showConfirmButton: true,
            timer: 4000
        })
    },
    showSuccessNotify: (title, msg) => {
        Swal.fire({
            title: title,
            text: msg,
            toast: true,
            icon: 'success',
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            customClass: {
                container: 'swalNotifyCustom',
            }
        })
    },
    showErrorNotify: (title, msg) => {
        Swal.fire({
            title: title,
            text: msg,
            toast: true,
            icon: 'error',
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            customClass: {
                container: 'swalNotifyCustom',
            }
        })
    },
    showConfirm: (title, msg, callbackOnSuccess, callbackOnCancel) => {
        Swal.fire({
            title: title,
            text: msg,
            icon: "warning",
            showCancelButton: true,
            buttons: [
                'Cancelar',
                'Continuar'
            ],
            confirmButtonText: 'Continuar',
            cancelButtonText: "Cancelar",
        }).then(function(result) {
            if (result.isConfirmed) {
                if (typeof callbackOnSuccess === 'function') callbackOnSuccess();
            }
            else {
                if (typeof callbackOnCancel === 'function') callbackOnCancel();
            }
        })
    }
}
