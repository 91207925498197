export default {
    getDefaultLang() {
        return {
            languageTag: "es-GT",
            delimiters: {
                thousands: ",",
                decimal: "."
            },
            abbreviations: {
                thousand: "k",
                million: "m",
                billion: "b",
                trillion: "t"
            },
            ordinal: (number) => {
                return number === 1 ? "quetzal" : "quetzales";
            },
            currency: {
                symbol: "Q",
                position: "prefix",
                code: "GTQ"
            },
            currencyFormat: {
                thousandSeparated: true,
                spaceSeparated: true,
                spaceSeparatedCurrency: true,
                mantissa: 2
            },
            formats: {
                fourDigits: {
                    totalLength: 4,
                    spaceSeparated: true
                },
                fullWithTwoDecimals: {
                    output: "currency",
                    thousandSeparated: true,
                    mantissa: 2
                },
                fullWithTwoDecimalsNoCurrency: {
                    thousandSeparated: true,
                    mantissa: 2
                },
                fullWithNoDecimals: {
                    output: "currency",
                    thousandSeparated: true,
                    mantissa: 0
                }
            }
        }
    }
}
