//import Lock from "../../views/Lock";
import Reset from "./views/Reset";
import Signin from "./views/Signin";
import Signup from "./views/Signup";
import AccountVerify from "./views/AccountVerify";

export default [
    /*{
        path: "/lock",
        name: "Lock",
        component: Lock,
        meta: {
            isPublic: true
        }
    },*/
    {
        path: "/signup",
        name: "Signup",
        component: Signup,
        meta: {
            isPublic: true
        }
    },
    {
        path: "/signin",
        name: "Signin",
        component: Signin,
        meta: {
            isPublic: true
        }
    },
    {
        path: "/reset/password",
        component: Reset,
        meta: {
            isPublic: true
        }
    },
    {
        path: "/reset/token/:token",
        component: Reset,
        meta: {
            isPublic: true
        }
    },
    {
        path: "/account-verify/:token",
        component: AccountVerify,
        meta: {
            isPublic: true
        }
    },
];
