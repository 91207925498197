<template>
    <div>


    </div>
</template>

<script>

import Bootstrap from 'src/assets/js/lib/bootstrap5.js';
import Splide from 'src/assets/js/lib/splide.js';
import {mapGetters} from "vuex";

export default {
    components: {

    },
    props: {
        active: Number,
        title: Boolean,
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo',
            AuthIsLogged: 'AuthIsLogged',
        })
    },
    mounted() {

    },
    unmounted() {
    },
    methods: {

    }
};
</script>
