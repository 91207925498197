<template>
    <div>
        <!-- App Capsule -->
        <div id="appCapsule">

            <div class="section mt-2 text-center">
                <h1>
                    <img class="logo" src="staticAssets/PayGuardian.png" alt="PayGuardian">
                </h1>
                <h3 class="mt-5 text-secondary">Verificación de cuenta</h3>
            </div>
            <div class="section mb-5 p-2">
                <div class="text-center pb-3">
                    <div class="card py-5">
                        <div v-if="!validated" class="card-body">
                            <div>
                                {{msg}}
                            </div>
                        </div>
                        <div v-else class="card-body">
                            <div>
                                {{msg}}
                            </div>
                            <div class="mt-2">
                                <i class="fa-solid fa-check fa-3x"></i>
                            </div>
                            <div class="mt-3">
                                <router-link class="btn btn-primary" to="/signin">
                                    Continuar
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {mapMutations} from "vuex";
import {mapGetters} from "vuex";
import {CONFIG} from 'src/config';
import {store} from '../../../store';
/*import publicStore from "src/assets/publicStore/css/theme.lazy.css";
import componentsStyleGlobal from "../../../assets/controlPanel/css/Components.lazy.css";*/

import DataValidation from "src/core/DataValidation";
import Tools from "src/core/Tools";
import API from "src/core/Api";

export default {
    name: "Signin",
    components: {},
    props: {
    },
    data() {
        return {
            validated: 0,
            msg: "Estamos validando tu cuenta",
            token: false,
        };
    },
    mounted() {
        this.token = this.$route.params.token;
        this.verifyAccount();
    },
    watch: {
    },
    computed: {
        ...mapGetters({
            StoreInfo: 'StoreInfo',
        })
    },
    methods: {
        ...mapGetters(["AuthIsLogged", "GetDeviceFingerprint"]),
        verifyAccount() {

            const self = this;

            const fp = self.GetDeviceFingerprint();

            store.dispatch('coreShowLoading');
            fetch(CONFIG.getWsUrl('payguardian/verify-account'), {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "x-pg-fp": fp.f,
                    "x-pg-n": fp.n,
                    "x-pg-ua": fp.ua,
                },
                body: JSON.stringify({
                    token: self.token,
                }),
            })
                .then(response => response.json())
                .then(data => {
                    if (typeof data.status !== 'undefined') {
                        if (data.status === 1) {
                            self.validated = 1;
                        }
                        else {
                            self.validated = 0;
                        }
                        self.msg = data.msg;
                    }
                    else {
                        self.validated = 0;
                        self.msg = "Error al verificar cuenta";
                    }
                    store.dispatch('coreHideLoading');
                })
                .catch((e) => {
                    store.dispatch('coreHideLoading');
                    self.msg = 'Error de conexión, por favor intente de nuevo';
                });
        },
    },
};
</script>
