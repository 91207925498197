import API from "src/core/Api";
import {store} from '../store'
import localDisk from "src/localDisk";

export default {
    objectIsEmpty: (obj) => {
        for (let prop in obj) {
            // eslint-disable-next-line no-prototype-builtins
            if (obj.hasOwnProperty(prop))
                return false;
        }
        return true;
    },
    currencyFormat: (amount) => {
        // Create our number formatter.
        var formatter = new Intl.NumberFormat('en-ES', {
            style: 'currency',
            currency: 'GTQ',

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });

        return formatter.format(amount);
    },
    copyToClipboard: (text, msg) => {

        if (!msg) msg = 'Copiado al portapapeles con éxito';
        //var text = "Example text to appear on clipboard";
        navigator.clipboard.writeText(text).then(function () {
            API.showSuccessNotify(msg);
        }, function () {
            API.showSuccessNotify('Error al copiar, el navegador no es compatible');
        });
    },
    pushToObject(object, data) {
        const tmp = {};
        let count = 0;
        for (let item in object) {
            tmp[count] = object[item];
            count++;
        }
        tmp[count] = data;
        return tmp;
    },
    stringCapitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
    createSlug(string) {
        //return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "").charAt(0).toLowerCase() + string.slice(1);
        return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/(?!\w|\s)./g, '-').replace(/\s+/g, '-').replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2').toLowerCase();
    },
    isEmptyString(str, strLen) {
        str = String(str);
        if (!strLen) {
            return (str === null || str.match(/^ *$/) !== null || str === '');
        }
        else {
            return ((str === null || str.match(/^ *$/) !== null || str === '') || (str.length < strLen));
        }
    },
    checkPasswordStrength(password) {
        //Regular Expressions
        const regex = [];
        regex.push("[A-Z]"); //For Uppercase Alphabet
        regex.push("[a-z]"); //For Lowercase Alphabet
        regex.push("[0-9]"); //For Numeric Digits
        regex.push("[$@$!%*#?&-.;=+]"); //For Special Characters

        let passed = 0;

        //Validation for each Regular Expression
        for (var i = 0; i < regex.length; i++) {
            if ((new RegExp(regex[i])).test(password)) {
                passed++;
            }
        }

        //Validation for Length of Password
        if (passed > 2 && password.length > 8) {
            passed++;
        }

        //Display of Status
        let color = "";
        let passwordStrength = "";
        switch (passed) {
            case 0:
                break;
            case 1:
                passwordStrength = "La contraseña es débil";
                color = "#d72d2d";
                break;
            case 2:
                passwordStrength = "La contraseña es débil";
                color = "darkorange";
                break;
            case 3:
                passwordStrength = "La contraseña es débil";
                break;
            case 4:
                passwordStrength = "La contraseña es fuerte";
                color = "Green";
                break;
            case 5:
                passwordStrength = "La contraseña es muy fuerte";
                color = "darkgreen";
                break;
        }

        return {
            passwordStrength: passed,
            passwordStrengthMsg: passwordStrength,
            passwordStrengthColor: color,
        }
    },

    // Cookies
    setCookie(name, value, days) {
        let expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    getCookie(name) {
        let nameEQ = name + "=";
        let ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    },

// Hash
    makeRandomHash(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    },

    // boostrap
    closeBoostrapModal(modalSelector) {
        const truck_modal = document.querySelector(modalSelector);
        const modal = bootstrap.Modal.getInstance(truck_modal);
        modal.hide();
    },
    openBoostrapModal(modalSelector) {
        //const truck_modal = document.querySelector(modalSelector);
        const truck_modal = document.querySelector(modalSelector);
        const modal = new bootstrap.Modal(truck_modal, {
            backdrop: 'static'
        });

        //const modal = bootstrap.Modal.getInstance(truck_modal);
        modal.show('show');
    },

    // para check de accesos y configuraciones
    check: {
        config(module, key) {
            const config = (store.getters.AuthGetUserInfo.configuration) ? store.getters.AuthGetUserInfo.configuration : {};
            if (typeof config[module] !== 'undefined') {
                if (typeof config[module][key] !== 'undefined') {
                    return config[module][key];
                }
            }
            return false;
        },
        access() {

        },
    },


    fingerprintDetect (callback) {

        const getBrowser = () => {
            const browserName = (function (agent) {        switch (true) {
                case agent.indexOf("edge") > -1: return "MS Edge";
                case agent.indexOf("edg/") > -1: return "MS Edge (Chromium)";
                case agent.indexOf("opr") > -1 && !!window.opr: return "Opera";
                case agent.indexOf("chrome") > -1 && !!window.chrome: return "Google Chrome";
                case agent.indexOf("trident") > -1: return "Microsoft IE";
                case agent.indexOf("firefox") > -1: return "Mozilla Firefox";
                case agent.indexOf("safari") > -1: return "Apple Safari";
                default: return "Navegador desconocido";
            }
            })(window.navigator.userAgent.toLowerCase());
            return browserName;
        }
        const getDeviceType = () => {
            let device = "Unknown";
            const ua = {
                "Generic Linux": /Linux/i,
                "Android": /Android/i,
                "BlackBerry": /BlackBerry/i,
                "Bluebird": /EF500/i,
                "Chrome OS": /CrOS/i,
                "Datalogic": /DL-AXIS/i,
                "Honeywell": /CT50/i,
                "iPad": /iPad/i,
                "iPhone": /iPhone/i,
                "iPod": /iPod/i,
                "Mac OS": /Macintosh/i,
                "Windows": /IEMobile|Windows/i,
                "Zebra": /TC70|TC55/i,
            }
            Object.keys(ua).map(v => navigator.userAgent.match(ua[v]) && (device = v));
            //console.log(navigator);
            return device;
        }

        const d = new Date();
        let time = d.getTime();
        let deviceFp = this.makeRandomHash(15);
        let cookieFp = this.getCookie('pygwalletfp');
        let startFp = localDisk.read('timeStart', 'pygwallet-fpdv');
        let fp = '';

        if (!cookieFp) {
            cookieFp = deviceFp;
            this.setCookie('pygwalletfp', cookieFp, 365);
        }
        if (!startFp) {
            startFp = time;
            localDisk.write('timeStart', startFp, 'pygwallet-fpdv');
        }
        fp = cookieFp + '.' + startFp;

        const fingerprint = {
            n: getBrowser() + " en " + getDeviceType(),
            ua: navigator.userAgent,
            f: fp,
        };
        callback(fingerprint);
    }
}
