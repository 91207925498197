<template>
    <div  class="card-main" :style="'background-image: url('+getBackgroundRandom()+')'">
        <div>
            <div class="card-button dropdown" v-if="parseInt(showControls) === 1">
                <button type="button" class="btn btn-link btn-icon" data-bs-toggle="dropdown">
                    <i class="fa-solid fa-cog"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-end">
                    <a class="dropdown-item" @click="setDefaultMethod(item.identifier)" v-if="parseInt(item.metodoDefault) === 0">
                        <i  class="fa-solid fa-flag"></i>&nbsp; Establecer predeterminado
                    </a>
                    <a class="dropdown-item" @click="deleteMethod(item.identifier)">
                        <i  class="fa-solid fa-trash"></i>&nbsp; Eliminar
                    </a>
                </div>
            </div>
            <div class="balance">
                <div class="row">
                    <div class="col-4">
                        <img src="staticAssets/paymentForm/chip2.png" class="card-chip"/>
                    </div>
                    <div class="col-8">
                        <div class="defaultFlag" v-if="parseInt(item.metodoDefault) === 1">
                            <i  class="fa-solid fa-flag"></i> &nbsp; Predeterminado
                        </div>
                    </div>
                </div>
            </div>
            <div class="in">
                <div class="card-number">
                    <span class="label">Número de tarjeta</span>
                    **** **** **** {{item.ccMask}}
                </div>
                <div class="bottom">
                    <div class="card-expiry">
                        <span class="label">Fecha de expiración</span>
                        {{item.ccMonth}} / {{item.ccYear}}
                    </div>
                </div>
                <div>
                    <img :src="'staticAssets/paymentForm/' + item.ccBrand.toString().toLowerCase() + '.png'" class="card-brand"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Layout from "src/modules/dashboard/components/Layout";
import API from "../../../core/Api";
import {CONFIG} from "src/config";

export default {
    name: "Dashboard",
    components: {
        //TimelineChart,
        Layout,
        //Toster,
    },
    props: ['item', 'showControls'],
    data() {
        return {
            payMethods: {}
        };
    },
    watch: {

    },
    mounted() {
    },
    methods: {
        getBackgroundRandom() {
            let random = Math.floor(Math.random() * 25 + 1)
            return `${CONFIG.PayGWalletUrl}/staticAssets/paymentForm/${random}.jpeg`
        },
        deleteMethod(identifier) {

            const self = this;

            API.showConfirm('¿Está seguro?', 'No podrá realizar más pagos con dicho método, si existen suscripciones recurrentes asociadas se cancelarán.', function () {
                API.send('DELETE', 'payments/paywallet/payment-method/delete', {
                        identifier: identifier,
                    },
                    function (response) {
                        if (response.status) {
                            API.showSuccessAlert('', response.msg, 0);
                            self.$emit('delete');
                        }
                        else {
                            API.showErrorNotify(response.msg);
                        }
                    },
                    function (response) {
                        //self.invalidLink = true;
                        API.showErrorNotify(response.msg);
                    });
            });
        },
        setDefaultMethod(identifier) {

            const self = this;

            API.showConfirm('¿Está seguro?', 'Todos los pagos se realizarán con el método predeterminado en caso no se seleccione un método distinto al pagar', function () {
                API.send('POST', 'payments/paywallet/payment-method/set-default', {
                        identifier: identifier,
                    },
                    function (response) {
                        if (response.status) {
                            API.showSuccessNotify('', response.msg);
                            self.$emit('update');
                        }
                        else {
                            API.showErrorNotify(response.msg);
                        }
                    },
                    function (response) {
                        //self.invalidLink = true;
                        API.showErrorNotify(response.msg);
                    });
            });
        },
    },
};
</script>
