import { createRouter, createWebHistory } from 'vue-router'
import { store } from "../store";
import { CONFIG } from "../config";
import AndroidBridge from "../core/AndroidBridge";

// load routes files
import dashboardRoutes from '../modules/dashboard/routes';
import authRoutes from '../modules/auth/routes';
import configurationRoutes from '../modules/configuration/routes';
import walletRoutes from '../modules/wallet/routes';
import Tools from "src/core/Tools";
import localDisk from "src/localDisk";

const routesCore = [
];

const routes = routesCore.concat(authRoutes, dashboardRoutes, configurationRoutes, walletRoutes);

const router = createRouter({
    history: createWebHistory(),
    //base: process.env.BASE_URL,
    routes,
});

// start android bride and ios
//const androidBridge = new AndroidBridge();

router.beforeEach((to, from, next) => {
    const isPublicRoute = to.matched.some(record => record.meta.isPublic);

    const fullDomain = window.location.href;
    const parsedUrl = new URL(fullDomain);

    // get fingerprint
    Tools.fingerprintDetect(function (fingerprintDevice) {
        store.dispatch('SetDeviceFingerprint', fingerprintDevice);

        if (parsedUrl.pathname === '/404') {
            next();
            return false;
        }
        else {
            // redirect a login
            if (to.path === '/' && !store.getters.AuthIsLogged) next('signin');
            if (to.path === '/' && store.getters.AuthIsLogged) next('dashboard');

            // si no tengo una ruta pública
            if (!isPublicRoute) {
                // valido si estoy logueado
                if (store.getters.AuthIsLogged) {
                    // voy a validar si el token esta ok
                    store.dispatch('AuthValidateToken', {
                        callback: (data) => {
                            //store.dispatch('coreHideLoading');
                            next();
                        },
                        routerGuard: next
                    });
                    next();
                }
                else {
                    localDisk.write('PyGwalletGoUrl', window.location.href);
                    store.dispatch('coreHideLoading');
                    next('signin');
                }
            }
            else {
                store.dispatch('coreHideLoading');
                next();
            }
        }
    });
});

export default router;
