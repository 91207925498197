import Items from "./views/Items";
import Cards from "./views/Cards";

export default [
    {
        path: "/wallet",
        component: Items,
    },
    {
        path: "/wallet/magnet/:magnetToken",
        component: Items,
    },
    {
        path: "/cards",
        component: Cards,
    },

];
