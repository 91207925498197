//import "bootstrap/dist/css/bootstrap.css";
import "vue-multiselect/dist/vue-multiselect.css";
import 'v-tooltip/dist/v-tooltip.css'
import "core-js/stable";
import "regenerator-runtime/runtime";

import { createApp, h } from "vue";
import App from './App.vue'
import router from "./router";
import 'sweetalert2/dist/sweetalert2.min.css';
import VueSweetalert2 from 'vue-sweetalert2';
import { store } from './store';
import VTooltipPlugin from 'v-tooltip'
//import '@vueup/vue-quill/dist/vue-quill.snow.css';
//import 'v-calendar/dist/style.css';
import 'src/assets/css/style.css';

const app  = createApp({
    render: ()=>h(App)
});
app.use(store);
app.use(router);
app.use(VueSweetalert2);
app.use(VTooltipPlugin);
app.config.productionTip = false;
app.mount("#app");
