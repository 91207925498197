<template>
    <div>
        <!-- App Capsule -->
        <div id="appCapsule">

            <div class="section mt-2 text-center">
                <h1>
                    <img class="logo" src="staticAssets/PayGuardian.png" alt="PayGuardian">
                </h1>
                <h3 class="mt-5 text-secondary">Recuperación de contraseña</h3>
            </div>
            <div class="section mb-5 p-2">
                <div v-if="passwordChangedOk" class="text-center pb-3">
                    <div class="card">
                        <div class="card-body">
                            <div>
                                Contraseña actualizada con éxito
                            </div>
                            <div class="mt-3">
                                <router-link class="btn btn-primary" to="/signin">
                                    Continuar
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!passwordChangedOk">
                    <form v-if="this.token" method="post" @submit.prevent="formChangePassword">
                        <div class="card">
                            <div class="card-body">
                                <div class="text-center mb-3">
                                    <h5 class="text-primary">Ingresa una contraseña nueva</h5>
                                </div>
                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="email1">Contraseña</label>
                                        <input type="password" class="form-control" placeholder="Ingresa una contraseña" v-model="password" name="password">
                                        <span class="validationError" v-if="passwordStrengthMsg" :style="'color:' + passwordStrengthColor">{{ passwordStrengthMsg }}</span>
                                    </div>
                                </div>
                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="email1">Confirmar contraseña</label>
                                        <input type="password" class="form-control" placeholder="Confirma la contraseña" v-model="confirmPassword" name="confirmPassword">
                                        <span class="validationError">{{ Valid.passwordConfirm }}</span>
                                    </div>
                                </div>
                                <div class="custom-control custom-checkbox mt-2 mb-1">
                                    <div class="loginAdvice text-danger mb-3">
                                        <small>{{ msg }}</small>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="form-button-group  transparent">
                            <button type="submit" class="btn btn-primary btn-block btn-lg">Cambiar contraseña</button>
                        </div>

                    </form>

                    <form v-else method="post" @submit.prevent="formSubmit">
                        <div class="card">
                            <div class="card-body">
                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="email1">Correo electrónico</label>
                                        <input type="email" class="form-control" placeholder="Ingresa tu correo electrónico" v-model="email" name="password">
                                        <span class="validationError">{{ Valid.email }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-links mt-2">
                            <div>
                                <router-link to="/signin" class="text-secondary">Iniciar sesión</router-link>
                            </div>
                            <div>
                                <router-link to="/signup" class="text-secondary">Registrarme</router-link>
                            </div>
                        </div>
                        <div class="mt-5">
                            <button type="submit" class="btn btn-primary btn-block btn-lg">Enviar enlace de recuperación</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {mapMutations} from "vuex";
import {mapGetters} from "vuex";
import {CONFIG} from 'src/config';
import {store} from '../../../store';
/*import publicStore from "src/assets/publicStore/css/theme.lazy.css";
import componentsStyleGlobal from "../../../assets/controlPanel/css/Components.lazy.css";*/

import DataValidation from "src/core/DataValidation";
import Tools from "src/core/Tools";
import API from "src/core/Api";

export default {
    name: "Signin",
    components: {},
    props: {
    },
    data() {
        return {
            Valid: {},
            email: "",
            msg: "",
            token: false,
            password: "",
            confirmPassword: "",
            passwordStrength: 0,
            passwordStrengthMsg: false,
            passwordStrengthColor: false,
            passwordChangedOk: false
        };
    },
    mounted() {
        this.token = this.$route.params.token;
    },
    watch: {
        password: function (value) {
            //console.log(value);
            this.CheckPasswordStrength(value);
        }
    },
    computed: {
        ...mapGetters({
            StoreInfo: 'StoreInfo',
        })
    },
    methods: {
        ...mapGetters(["AuthIsLogged", "GetDeviceFingerprint"]),
        ...mapMutations(["AuthSetToken", "SetUserInfo"]),
        formSubmit() {

            const self = this;

            const fp = self.GetDeviceFingerprint();

            const validation = new DataValidation()
            validation.email('email', this.email, 'Ingrese un email válido');

            this.Valid = validation.validate(function () {

                store.dispatch('coreShowLoading');

                fetch(CONFIG.getWsUrl('payguardian/reset/'), {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "x-pg-fp": fp.f,
                        "x-pg-n": fp.n,
                        "x-pg-ua": fp.ua,
                    },
                    body: JSON.stringify({
                        email: self.email,
                    }),
                })
                    .then(response => response.json())
                    .then(data => {
                        if (typeof data.status !== 'undefined') {
                            if (data.status) {
                                self.msg = data.msg;
                            }
                            store.dispatch('coreHideLoading');
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        store.dispatch('coreHideLoading');
                        self.msg = 'Error de conexión, por favor intente de nuevo';
                    });
            });
        },
        formChangePassword() {

            const self = this;

            const validation = new DataValidation()
            validation.required('passwordConfirm', this.confirmPassword, 'Por favor confirma la contraseña');

            this.Valid = validation.validate(function () {

                if (self.confirmPassword !== self.password) {
                    API.showErrorNotify("Las contraseñas no coinciden");
                    return false;
                }

                if (self.passwordStrength < 4) {
                    API.showErrorNotify("Ingresa una contraseña más fuerte");
                    return false;
                }

                store.dispatch('coreShowLoading');

                fetch(CONFIG.getWsUrl('payguardian/reset/'), {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        token: self.token,
                        password: self.password,
                    }),
                })
                    .then(response => response.json())
                    .then(data => {
                        if (typeof data.status !== 'undefined') {

                            if (data.status) {
                                self.passwordChangedOk = true;
                            }
                            else {
                                self.msg = data.msg;
                            }
                            store.dispatch('coreHideLoading');
                        }
                    })
                    .catch(() => {
                        store.dispatch('coreHideLoading');
                        self.msg = 'Error de conexión, por favor intente de nuevo';
                    });
            });
        },
        CheckPasswordStrength(password) {
            const passwordSt = Tools.checkPasswordStrength(password);

            this.passwordStrength = passwordSt.passwordStrength;
            this.passwordStrengthMsg = passwordSt.passwordStrengthMsg;
            this.passwordStrengthColor = passwordSt.passwordStrengthColor;

        }
    },
};
</script>
