<template>
    <layout :active="1" :title="true">
        <!-- App Capsule -->
        <div id="appCapsule">


            <!-- Wallet Card -->
            <div class="section wallet-card-section pt-1">
                <div class="wallet-card">
                    <!-- Balance -->
                    <div class="balance">
                        <div class="left">
                            <div class="pt-2">
                                <h2>QR de pago Cashless</h2>
                            </div>
                            <div>
                                <h3 class="text-muted">
                                    Puedes generar un QR de pago presionando el ícono a la derecha.
                                </h3>
                            </div>
                        </div>
                        <div class="right">
                            <!-- * Deposit Action Sheet -->
                            <a class="button cursor-pointer" @click="startQrGeneration">
                                <i class="fa-solid fa-qrcode fa-shake" style="--fa-animation-duration: 3s;"></i>
                            </a>
                        </div>
                    </div>
                    <!-- * Balance -->
                    <!-- Wallet Footer -->
                    <!--                    <div class="wallet-footer">
                                            <div class="item">
                                                <a href="#" data-bs-toggle="modal" data-bs-target="#withdrawActionSheet">
                                                    <div class="icon-wrapper bg-danger">
                                                        <ion-icon name="arrow-down-outline"></ion-icon>
                                                    </div>
                                                    <strong>Withdraw</strong>
                                                </a>
                                            </div>
                                            <div class="item">
                                                <a href="#" data-bs-toggle="modal" data-bs-target="#sendActionSheet">
                                                    <div class="icon-wrapper">
                                                        <ion-icon name="arrow-forward-outline"></ion-icon>
                                                    </div>
                                                    <strong>Send</strong>
                                                </a>
                                            </div>
                                            <div class="item">
                                                <a href="app-cards.html">
                                                    <div class="icon-wrapper bg-success">
                                                        <ion-icon name="card-outline"></ion-icon>
                                                    </div>
                                                    <strong>Cards</strong>
                                                </a>
                                            </div>
                                            <div class="item">
                                                <a href="#" data-bs-toggle="modal" data-bs-target="#exchangeActionSheet">
                                                    <div class="icon-wrapper bg-warning">
                                                        <ion-icon name="swap-vertical"></ion-icon>
                                                    </div>
                                                    <strong>Exchange</strong>
                                                </a>
                                            </div>

                                        </div>-->
                    <!-- * Wallet Footer -->
                </div>
            </div>


            <!-- Deposit Action Sheet -->
            <div class="modal fade action-sheet" id="generateQrPayModal" role="dialog">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Generar QR de pago</h5>
                            <i class="fa-solid fa-times-circle closeModal" @click="stopQrGeneration"></i>
                        </div>
                        <div class="modal-body">
                            <div class="action-sheet-content">
                                <div class="text-center">
                                    <div v-if="qrGeneratedValue" class="mb-4">
                                        <div class="mb-2">
                                            <h2>El código cambiará en <span class="text-warning">{{qrTimeCount}} seg</span></h2>
                                        </div>
                                        <qrcode-vue :value="qrGeneratedValue" size="300" level="H" />
                                    </div>
                                </div>
                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="account1">
                                            Seleccione método de pago: <i v-if="!qrGeneratedValue" class="fas fa-clock fa-spin" style="--fa-animation-duration: 4s; color: #003672"></i>
                                        </label>
                                        <select class="form-control custom-select" id="account1" v-model="selectedPaymentMethod">
                                            <option v-for="item in payMethods" :key="'pm_'+item.identifier" :value="item.identifier">Predeterminada: ({{item.ccBrand}}) Fin: {{item.ccMask}} - Exp: {{item.ccMonth}}/{{item.ccYear}}</option>
                                        </select>
                                    </div>
                                </div>

                                <!--  <div class="form-group basic">
                                        <label class="label">Ingrese el monto</label>
                                        <div class="input-group mb-2">
                                            <span class="input-group-text" id="basic-addona1">$</span>
                                            <input type="text" class="form-control" placeholder="Enter an amount"
                                                value="100">
                                        </div>
                                    </div>-->

                                <div class="form-group basic">
                                    <button type="button" class="btn btn-primary btn-block btn-lg" @click="generateQRCode">Generar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- Stats -->
            <!--            <div class="section">
                            <div class="row mt-2">
                                <div class="col-6">
                                    <div class="stat-box">
                                        <div class="title">Income</div>
                                        <div class="value text-success">$ 552.95</div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="stat-box">
                                        <div class="title">Expenses</div>
                                        <div class="value text-danger">$ 86.45</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-6">
                                    <div class="stat-box">
                                        <div class="title">Total Bills</div>
                                        <div class="value">$ 53.25</div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="stat-box">
                                        <div class="title">Savings</div>
                                        <div class="value">$ 120.99</div>
                                    </div>
                                </div>
                            </div>
                        </div>-->
            <!-- * Stats -->

            <!-- transacciones -->
            <div class="section mt-4">
                <div class="section-heading">
                    <h2 class="title">Transacciones</h2>
<!--                    <a @click="$router.push('/transactions')" class="link">Ver todas</a>-->
                </div>
                <div class="transactions">
                    <a v-for="item in transactions" class="item">
                        <div class="detail">
                            <div>
                                <p>
                                    <span class="fw-bold text-primary">Autorización: </span> {{ item.autorizacion }}
                                </p>
                                <p>
                                    <span class="fw-bold text-primary">Fecha: </span> {{ item.date }}
                                </p>
                                <p>
                                    <span class="fw-bold text-primary">Monto: </span> {{ item.monto }}
                                </p>
                                <p>
                                    <span class="fw-bold text-primary">Método de pago: </span> {{item.ccBrand}}, finaliza en: {{ item.ccMask }}
                                </p>
                            </div>
                        </div>
                        <!--<div class="right">
                            <div class="price text-danger"> - $ 150</div>
                        </div>-->
                    </a>
                </div>
                <div v-if="Object.keys(transactions).length === 0" class="text-center text-muted">
                    No existen transacciones
                    <hr>
                </div>
            </div>
            <!-- * Transactions -->

            <!-- billetera -->
            <div class="section mt-4">
                <div class="section-heading">
                    <h2 class="title">Billetera</h2>
                    <a @click="$router.push('/wallet')" class="link">Detalles</a>
                </div>
                <div class="transactions">
                    <a v-for="item in walletItems" class="item">
                        <div class="detail">
                            <img :src="item.externalImage" alt="img" class="image-block imaged w48">
                            <div>
                                <strong>{{ item.itemName }}</strong>
                                <p>Código: {{item.code}}</p>
                                <p>{{item.buyerName}}</p>
                            </div>
                        </div>
                        <!--<div class="right">
                            <div class="price text-danger"> - $ 150</div>
                        </div>-->
                    </a>
                </div>
                <div v-if="Object.keys(walletItems).length === 0" class="text-center text-muted">
                    La billetera está vacía
                    <hr>
                </div>
                <div class="text-center mt-1">
                    <div @click="$router.push('/wallet#add')" class="cursor-pointer">
                        <h5 class="text-primary"><i class="fa-solid fa-plus-circle"></i> &nbsp; Asociar item</h5>
                    </div>
                </div>
            </div>
            <!-- * Transactions -->

            <!-- my cards -->
            <div class="section full mt-4">
                <div class="section-heading padding">
                    <h2 class="title">Mis tarjetas</h2>
                    <a @click="$router.push('/cards')" class="link">Editar</a>
                </div>

                <!-- carousel single -->
                <div class="carousel-single splide">
                    <div class="splide__track">
                        <ul class="splide__list">
                            <li v-for="item in payMethods" class="splide__slide" :key="item.identifier" :ref="item.identifier">
                                <div class="card-block">
                                    <pay-card :item="item" show-controls="0"></pay-card>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-if="Object.keys(payMethods).length === 0" class="text-center text-muted p-4">
                    Sin métodos de pago
                    <hr>
                </div>
                <div class="text-center mt-3">
                    <div @click="$router.push('/cards#add')" class="cursor-pointer">
                        <h5 class="text-primary"><i class="fa-solid fa-plus-circle"></i> &nbsp; Agregar nueva</h5>
                    </div>
                </div>
                <!-- * carousel single -->

            </div>
            <!-- * my cards -->

            <!-- Send Money -->
            <!--            <div class="section full mt-4">
                            <div class="section-heading padding">
                                <h2 class="title">Send Money</h2>
                                <a href="#" class="link">Add New</a>
                            </div>
                            &lt;!&ndash; carousel small &ndash;&gt;
                            <div class="carousel-small splide">
                                <div class="splide__track">
                                    <ul class="splide__list">
                                        <li class="splide__slide">
                                            <a href="#">
                                                <div class="user-card">
                                                    <img src="assets/img/sample/avatar/avatar2.jpg" alt="img" class="imaged w-100">
                                                    <strong>Jurrien</strong>
                                                </div>
                                            </a>
                                        </li>
                                        <li class="splide__slide">
                                            <a href="#">
                                                <div class="user-card">
                                                    <img src="assets/img/sample/avatar/avatar3.jpg" alt="img" class="imaged w-100">
                                                    <strong>Elwin</strong>
                                                </div>
                                            </a>
                                        </li>
                                        <li class="splide__slide">
                                            <a href="#">
                                                <div class="user-card">
                                                    <img src="assets/img/sample/avatar/avatar4.jpg" alt="img" class="imaged w-100">
                                                    <strong>Alma</strong>
                                                </div>
                                            </a>
                                        </li>
                                        <li class="splide__slide">
                                            <a href="#">
                                                <div class="user-card">
                                                    <img src="assets/img/sample/avatar/avatar5.jpg" alt="img" class="imaged w-100">
                                                    <strong>Justine</strong>
                                                </div>
                                            </a>
                                        </li>
                                        <li class="splide__slide">
                                            <a href="#">
                                                <div class="user-card">
                                                    <img src="assets/img/sample/avatar/avatar6.jpg" alt="img" class="imaged w-100">
                                                    <strong>Maria</strong>
                                                </div>
                                            </a>
                                        </li>
                                        <li class="splide__slide">
                                            <a href="#">
                                                <div class="user-card">
                                                    <img src="assets/img/sample/avatar/avatar7.jpg" alt="img" class="imaged w-100">
                                                    <strong>Pamela</strong>
                                                </div>
                                            </a>
                                        </li>
                                        <li class="splide__slide">
                                            <a href="#">
                                                <div class="user-card">
                                                    <img src="assets/img/sample/avatar/avatar8.jpg" alt="img" class="imaged w-100">
                                                    <strong>Neville</strong>
                                                </div>
                                            </a>
                                        </li>
                                        <li class="splide__slide">
                                            <a href="#">
                                                <div class="user-card">
                                                    <img src="assets/img/sample/avatar/avatar9.jpg" alt="img" class="imaged w-100">
                                                    <strong>Alex</strong>
                                                </div>
                                            </a>
                                        </li>
                                        <li class="splide__slide">
                                            <a href="#">
                                                <div class="user-card">
                                                    <img src="assets/img/sample/avatar/avatar10.jpg" alt="img" class="imaged w-100">
                                                    <strong>Stina</strong>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            &lt;!&ndash; * carousel small &ndash;&gt;
                        </div>-->
            <!-- * Send Money -->

            <!-- Monthly Bills -->
            <!--            <div class="section full mt-4">
                            <div class="section-heading padding">
                                <h2 class="title">Monthly Bills</h2>
                                <a href="app-bills.html" class="link">View All</a>
                            </div>
                            &lt;!&ndash; carousel multiple &ndash;&gt;
                            <div class="carousel-multiple splide">
                                <div class="splide__track">
                                    <ul class="splide__list">

                                        <li class="splide__slide">
                                            <div class="bill-box">
                                                <div class="img-wrapper">
                                                    <img src="assets/img/sample/brand/1.jpg" alt="img" class="image-block imaged w48">
                                                </div>
                                                <div class="price">$ 14</div>
                                                <p>Prime Monthly Subscription</p>
                                                <a href="#" class="btn btn-primary btn-block btn-sm">PAY NOW</a>
                                            </div>
                                        </li>

                                        <li class="splide__slide">
                                            <div class="bill-box">
                                                <div class="img-wrapper">
                                                    <img src="assets/img/sample/brand/2.jpg" alt="img" class="image-block imaged w48">
                                                </div>
                                                <div class="price">$ 9</div>
                                                <p>Music Monthly Subscription</p>
                                                <a href="#" class="btn btn-primary btn-block btn-sm">PAY NOW</a>
                                            </div>
                                        </li>

                                        <li class="splide__slide">
                                            <div class="bill-box">
                                                <div class="img-wrapper">
                                                    <div class="iconbox bg-danger">
                                                        <ion-icon name="medkit-outline"></ion-icon>
                                                    </div>
                                                </div>
                                                <div class="price">$ 299</div>
                                                <p>Monthly Health Insurance</p>
                                                <a href="#" class="btn btn-primary btn-block btn-sm">PAY NOW</a>
                                            </div>
                                        </li>

                                        <li class="splide__slide">
                                            <div class="bill-box">
                                                <div class="img-wrapper">
                                                    <div class="iconbox">
                                                        <ion-icon name="card-outline"></ion-icon>
                                                    </div>
                                                </div>
                                                <div class="price">$ 962</div>
                                                <p>Credit Card Statement
                                                </p>
                                                <a href="#" class="btn btn-primary btn-block btn-sm">PAY NOW</a>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                            &lt;!&ndash; * carousel multiple &ndash;&gt;
                        </div>-->
            <!-- * Monthly Bills -->


            <!-- Saving Goals -->
            <!--            <div class="section mt-4">
                            <div class="section-heading">
                                <h2 class="title">Saving Goals</h2>
                                <a href="app-savings.html" class="link">View All</a>
                            </div>
                            <div class="goals">
                                &lt;!&ndash; item &ndash;&gt;
                                <div class="item">
                                    <div class="in">
                                        <div>
                                            <h4>Gaming Console</h4>
                                            <p>Gaming</p>
                                        </div>
                                        <div class="price">$ 499</div>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar" role="progressbar" style="width: 85%;" aria-valuenow="85"
                                            aria-valuemin="0" aria-valuemax="100">85%</div>
                                    </div>
                                </div>
                                &lt;!&ndash; * item &ndash;&gt;
                                &lt;!&ndash; item &ndash;&gt;
                                <div class="item">
                                    <div class="in">
                                        <div>
                                            <h4>New House</h4>
                                            <p>Living</p>
                                        </div>
                                        <div class="price">$ 100,000</div>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar" role="progressbar" style="width: 55%;" aria-valuenow="55"
                                            aria-valuemin="0" aria-valuemax="100">55%</div>
                                    </div>
                                </div>
                                &lt;!&ndash; * item &ndash;&gt;
                                &lt;!&ndash; item &ndash;&gt;
                                <div class="item">
                                    <div class="in">
                                        <div>
                                            <h4>Sport Car</h4>
                                            <p>Lifestyle</p>
                                        </div>
                                        <div class="price">$ 42,500</div>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar" role="progressbar" style="width: 15%;" aria-valuenow="15"
                                            aria-valuemin="0" aria-valuemax="100">15%</div>
                                    </div>
                                </div>
                                &lt;!&ndash; * item &ndash;&gt;
                            </div>
                        </div>-->
            <!-- * Saving Goals -->


            <!-- News -->
            <!--            <div class="section full mt-4 mb-3">
                            <div class="section-heading padding">
                                <h2 class="title">Lastest News</h2>
                                <a href="app-blog.html" class="link">View All</a>
                            </div>

                            &lt;!&ndash; carousel multiple &ndash;&gt;
                            <div class="carousel-multiple splide">
                                <div class="splide__track">
                                    <ul class="splide__list">

                                        <li class="splide__slide">
                                            <a href="app-blog-post.html">
                                                <div class="blog-card">
                                                    <img src="assets/img/sample/photo/1.jpg" alt="image" class="imaged w-100">
                                                    <div class="text">
                                                        <h4 class="title">What will be the value of bitcoin in the next...</h4>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>

                                        <li class="splide__slide">
                                            <a href="app-blog-post.html">
                                                <div class="blog-card">
                                                    <img src="assets/img/sample/photo/2.jpg" alt="image" class="imaged w-100">
                                                    <div class="text">
                                                        <h4 class="title">Rules you need to know in business</h4>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>

                                        <li class="splide__slide">
                                            <a href="app-blog-post.html">
                                                <div class="blog-card">
                                                    <img src="assets/img/sample/photo/3.jpg" alt="image" class="imaged w-100">
                                                    <div class="text">
                                                        <h4 class="title">10 easy ways to save your money</h4>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>

                                        <li class="splide__slide">
                                            <a href="app-blog-post.html">
                                                <div class="blog-card">
                                                    <img src="assets/img/sample/photo/4.jpg" alt="image" class="imaged w-100">
                                                    <div class="text">
                                                        <h4 class="title">Follow the financial agenda with...</h4>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                            &lt;!&ndash; * carousel multiple &ndash;&gt;

                        </div>-->
            <!-- * News -->


            <!-- * App Capsule -->
        </div>

        <!-- Withdraw Action Sheet -->
        <div class="modal fade action-sheet" id="withdrawActionSheet" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Withdraw Money</h5>
                    </div>
                    <div class="modal-body">
                        <div class="action-sheet-content">
                            <form>
                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="account2d">From</label>
                                        <select class="form-control custom-select" id="account2d">
                                            <option value="0">Savings (*** 5019)</option>
                                            <option value="1">Investment (*** 6212)</option>
                                            <option value="2">Mortgage (*** 5021)</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="text11d">To</label>
                                        <input type="email" class="form-control" id="text11d" placeholder="Enter IBAN">
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>

                                <div class="form-group basic">
                                    <label class="label">Enter Amount</label>
                                    <div class="input-group mb-2">
                                        <span class="input-group-text" id="basic-addonb1">$</span>
                                        <input type="text" class="form-control" placeholder="Enter an amount"
                                            value="100">
                                    </div>
                                </div>

                                <div class="form-group basic">
                                    <button type="button" class="btn btn-primary btn-block btn-lg"
                                        data-bs-dismiss="modal">Withdraw
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * Withdraw Action Sheet -->

        <!-- Send Action Sheet -->
        <div class="modal fade action-sheet" id="sendActionSheet" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Send Money</h5>
                    </div>
                    <div class="modal-body">
                        <div class="action-sheet-content">
                            <form>
                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="account2">From</label>
                                        <select class="form-control custom-select" id="account2">
                                            <option value="0">Savings (*** 5019)</option>
                                            <option value="1">Investment (*** 6212)</option>
                                            <option value="2">Mortgage (*** 5021)</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="text11">To</label>
                                        <input type="email" class="form-control" id="text11"
                                            placeholder="Enter bank ID">
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>

                                <div class="form-group basic">
                                    <label class="label">Enter Amount</label>
                                    <div class="input-group mb-2">
                                        <span class="input-group-text" id="basic-addon1">$</span>
                                        <input type="text" class="form-control" placeholder="Enter an amount"
                                            value="100">
                                    </div>
                                </div>

                                <div class="form-group basic">
                                    <button type="button" class="btn btn-primary btn-block btn-lg"
                                        data-bs-dismiss="modal">Send
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * Send Action Sheet -->

        <!-- Exchange Action Sheet -->
        <div class="modal fade action-sheet" id="exchangeActionSheet" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Exchange Money</h5>
                    </div>
                    <div class="modal-body">
                        <div class="action-sheet-content">
                            <form>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group basic">
                                            <div class="input-wrapper">
                                                <label class="label" for="currency1">From</label>
                                                <select class="form-control custom-select" id="currency1">
                                                    <option value="1">EUR</option>
                                                    <option value="2">USD</option>
                                                    <option value="3">AUD</option>
                                                    <option value="4">CAD</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group basic">
                                            <div class="input-wrapper">
                                                <label class="label" for="currency2">To</label>
                                                <select class="form-control custom-select" id="currency2">
                                                    <option value="1">USD</option>
                                                    <option value="1">EUR</option>
                                                    <option value="2">AUD</option>
                                                    <option value="3">CAD</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group basic">
                                    <label class="label">Enter Amount</label>
                                    <div class="input-group mb-2">
                                        <span class="input-group-text" id="basic-addon2">$</span>
                                        <input type="text" class="form-control" placeholder="Enter an amount"
                                            value="100">
                                    </div>
                                </div>


                                <div class="form-group basic">
                                    <button type="button" class="btn btn-primary btn-block btn-lg"
                                        data-bs-dismiss="modal">Exchange
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * Exchange Action Sheet -->

    </layout>
</template>

<script>
//import TimelineChart from "../components/charts/TimelineChart.vue";
import Layout from "../components/Layout.vue";
import payCard from "src/modules/wallet/components/Card"
import GenQrCode from "src/modules/payment/components/GenQrCode";
import API from "../../../core/Api";
import {CONFIG} from "src/config";
import Tools from "src/core/Tools";
import QrcodeVue from 'qrcode.vue'

export default {
    name: "Dashboard",
    components: {
        //TimelineChart,
        Layout,
        GenQrCode,
        payCard,
        QrcodeVue,
    },
    data() {
        return {
            qrUrl: "",

            // Metodos de pago
            payMethods: {},

            // QR gen
            qrModalOpen: false,
            selectedPaymentMethod: '',
            paymentLockedAmount: 0,
            qrGeneratedValue: false,
            qrTimeCount: 0,
            qrTimeout: false,

            // Billetera
            walletItems: {},

            // transacciones
            transactions: {}
        };
    },
    watch: {

        /*active: function (val) {
            const self = this;
            if (val) {
                API.send('GET', 'reports/general/'+self.active, {},
                    function (response) {
                        self.reportes = response.data;
                        console.log(self.reportes);
                    },
                    function () {
                        //self.invalidLink = true;
                        API.showErrorAlert(response.msg);
                    });
            }
        }*/
    },
    methods: {
        getMethods() {
            const self = this;
            API.send('GET', 'payments/paywallet/payment-method/get-all', {},
                function (response) {
                    if (response.status) {
                        self.payMethods = response.data;

                        setTimeout(function () {
                            document.querySelectorAll('.carousel-single').forEach(carousel => new Splide(carousel, {
                                perPage: 3,
                                rewind: true,
                                type: "loop",
                                gap: 16,
                                padding: 16,
                                arrows: false,
                                pagination: false,
                                breakpoints: {
                                    768: {
                                        perPage: 1
                                    },
                                    991: {
                                        perPage: 2
                                    }
                                }
                            }).mount());
                        }, 100);
                    }
                },
                function () {
                    //self.invalidLink = true;
                    API.showErrorAlert(response.msg);
                });
        },
        getTransactions() {
            const self = this;
            API.send('GET', 'payguardian/transactions/', {},
                function (response) {
                    if (response.status) {
                        self.transactions = response.data;
                    }
                },
                function (response) {
                    //self.invalidLink = true;
                    API.showErrorAlert(response.msg);
                }, {
                    disableLoading: true
                });
        },
        getItemsWallet() {
            const self = this;
            API.send('GET', 'payguardian/wallet/get/item', {},
                function (response) {
                    if (response.status) {
                        self.walletItems = response.data;
                    }
                },
                function (response) {
                    //self.invalidLink = true;
                    API.showErrorAlert(response.msg);
                }, {
                    disableLoading: true
                });
        },
        startQrGeneration() {
            const self = this;
            this.qrModalOpen = true;

            if (Object.keys(self.payMethods).length === 0) {
                API.showConfirm('', 'Aún no tienes configurados métodos de pago, ¿deseas agregar uno nuevo?', function () {
                    self.$router.push('/cards#add');
                });
                return false;
            }

            // selecciono la predeterminada
            Object.keys(self.payMethods).forEach(function (a, b){
                if (parseInt(self.payMethods[a].metodoDefault) === 1) {
                    self.selectedPaymentMethod = self.payMethods[a].identifier;
                }
            });

            Tools.openBoostrapModal('#generateQrPayModal');
        },
        stopQrGeneration() {
            this.qrModalOpen = false;
            this.selectedPaymentMethod = false;
            this.qrGeneratedValue = false;
            if (this.qrTimeout) {
                clearTimeout(self.qrTimeout);
            }
            Tools.closeBoostrapModal('#generateQrPayModal');
        },
        generateQRCode() {

            const self = this;
            if (!this.qrModalOpen) {
                return false;
            }

            if (self.qrTimeout) {
                clearTimeout(self.qrTimeout);
            }

            self.qrTimeCount = 60;
            const countdown = function () {
                if(self.qrTimeCount > 1) {
                    self.qrTimeCount--;

                    if (self.qrGeneratedValue) {
                        self.qrTimeout = setTimeout(function() {
                            countdown()
                        },1000);
                    }
                }
                else {
                    self.generateQRCode();
                }
            }

            API.send('POST', 'payments/paywallet/payment/token/create', {
                    identifier: self.selectedPaymentMethod,
                    amount: self.paymentLockedAmount,
                },
                function (response) {
                    if (response.status) {

                        // envio la info al qr
                        self.qrGeneratedValue = response.data.dataToQr;

                        countdown();
                    }
                    else{
                        API.showErrorNotify('Error al generar código de pago');
                    }
                },
                function (response) {
                    //self.invalidLink = true;
                    API.showErrorAlert(response.msg);
                }, {
                    disableLoading: true
                });
        },
    },
    mounted() {
        const self = this;
        this.getTransactions();
        this.getMethods();
        this.getItemsWallet();

        setTimeout(function () {
            if (window.location.hash === '#payqr') {
                self.startQrGeneration();
            }
        }, 500);
    }
};
</script>
