<template>
    <div>
        <!-- App Header -->
        <div class="appHeader bg-primary text-light">
            <div class="left">
                <a class="headerButton" data-bs-toggle="modal" data-bs-target="#sidebarPanel">
                    <i class="fa-solid fa-bars"></i>
                </a>
            </div>
            <div class="pageTitle">
                <a @click="$router.push('/dashboard')" class="cursor-pointer">
                    <img src="staticAssets/PayGuardianLight.png" alt="logo" class="logo">
                </a>
            </div>
            <div class="right">
                <a class="headerButton">
                    <i class="fa-solid fa-bell"></i>
<!--                    <span class="badge badge-danger">4</span>-->
                </a>
            </div>
        </div>
        <!-- * App Header -->

        <slot/>

        <!-- App Sidebar -->
        <div class="modal fade panelbox panelbox-left" id="sidebarPanel" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body p-0">
                        <!-- profile box -->
                        <div class="profileBox pt-2 pb-2">
                            <div class="image-wrapper">
                                <i class="fa-solid fa-user"></i>
                            </div>
                            <div class="in">
                                <strong>{{AuthGetUserInfo.nombre}}</strong>
                                <div class="text-muted">{{AuthGetUserInfo.email}}</div>
                            </div>
                            <a href="#" class="btn btn-link btn-icon sidebar-close" data-bs-dismiss="modal">
                                <i class="fa-solid fa-times-circle"></i>
                            </a>
                        </div>
                        <!-- * profile box -->
                        <!-- balance -->
                        <div class="sidebar-balance">
                            <div class="listview-title">Consumos del día</div>
                            <div class="in mb-2">
                                <h1 class="amount">Q. 0.00</h1>
                            </div>
                        </div>
                        <!-- * balance -->

                        <!-- action group -->
<!--                        <div class="action-group">
                            <a href="index.html" class="action-button">
                                <div class="in">
                                    <div class="iconbox">
                                        <ion-icon name="add-outline"></ion-icon>
                                    </div>
                                    Deposit
                                </div>
                            </a>
                            <a href="index.html" class="action-button">
                                <div class="in">
                                    <div class="iconbox">
                                        <ion-icon name="arrow-down-outline"></ion-icon>
                                    </div>
                                    Withdraw
                                </div>
                            </a>
                            <a href="index.html" class="action-button">
                                <div class="in">
                                    <div class="iconbox">
                                        <ion-icon name="arrow-forward-outline"></ion-icon>
                                    </div>
                                    Send
                                </div>
                            </a>
                            <a href="app-cards.html" class="action-button">
                                <div class="in">
                                    <div class="iconbox">
                                        <ion-icon name="card-outline"></ion-icon>
                                    </div>
                                    My Cards
                                </div>
                            </a>
                        </div>-->
                        <!-- * action group -->

                        <!-- menu -->
                        <div class="listview-title mt-1">General</div>
                        <ul class="listview flush transparent no-line image-listview">
                            <li>
                                <a @click="$router.push('/dashboard')" class="item">
                                    <div class="icon-box bg-primary">
                                        <i class="fa-solid fa-home"></i>
                                    </div>
                                    <div class="in">
                                        Inicio
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a @click="$router.push('/cards')" class="item">
                                    <div class="icon-box bg-primary">
                                        <i class="fa-solid fa-credit-card-alt"></i>
                                    </div>
                                    <div class="in">
                                        Mis tarjetas
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a @click="$router.push('/dashboard')" class="item">
                                    <div class="icon-box bg-primary">
                                        <i class="fa-solid fa-chart-column"></i>
                                    </div>
                                    <div class="in">
                                        Transacciones
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <!-- * menu -->

                        <!-- others -->
                        <div class="listview-title mt-1">Billetera</div>
                        <ul class="listview flush transparent no-line image-listview">
                            <li>
                                <a @click="$router.push('/wallet')"  class="item">
                                    <div class="icon-box bg-primary">
                                        <i class="fa-solid fa-wallet"></i>
                                    </div>
                                    <div class="in">
                                        Mi billetera
                                    </div>
                                </a>
                            </li>
<!--                            <li>
                                <a @click="$router.push('/dashboard')" class="item">
                                    <div class="icon-box bg-primary">
                                        <i class="fa-solid fa-calendar"></i>
                                    </div>
                                    <div class="in">
                                        Calendario
                                    </div>
                                </a>
                            </li>-->
                        </ul>

                        <!-- others -->
                        <div class="listview-title mt-1">Otros</div>
                        <ul class="listview flush transparent no-line image-listview">
                            <li>
                                <a @click="$router.push('/configuration')"  class="item">
                                    <div class="icon-box bg-primary">
                                        <i class="fa-solid fa-cog"></i>
                                    </div>
                                    <div class="in">
                                        Configuración
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a @click="logout" class="item">
                                    <div class="icon-box bg-primary">
                                        <i class="fa-solid fa-sign-out"></i>
                                    </div>
                                    <div class="in">
                                        Cerrar sesión
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <!-- * others -->

                        <!-- send money -->
<!--                        <div class="listview-title mt-1">Send Money</div>
                        <ul class="listview image-listview flush transparent no-line">
                            <li>
                                <a href="#" class="item">
                                    <img src="assets/img/sample/avatar/avatar2.jpg" alt="image" class="image">
                                    <div class="in">
                                        <div>Artem Sazonov</div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="item">
                                    <img src="assets/img/sample/avatar/avatar4.jpg" alt="image" class="image">
                                    <div class="in">
                                        <div>Sophie Asveld</div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="item">
                                    <img src="assets/img/sample/avatar/avatar3.jpg" alt="image" class="image">
                                    <div class="in">
                                        <div>Kobus van de Vegte</div>
                                    </div>
                                </a>
                            </li>
                        </ul>-->
                        <!-- * send money -->

                    </div>
                </div>
            </div>
        </div>
        <!-- * App Sidebar -->

        <!-- iOS Add to Home Action Sheet -->
<!--        <div class="modal inset fade action-sheet ios-add-to-home" id="ios-add-to-home-screen" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Add to Home Screen</h5>
                        <a href="#" class="close-button" data-bs-dismiss="modal">
                            <ion-icon name="close"></ion-icon>
                        </a>
                    </div>
                    <div class="modal-body">
                        <div class="action-sheet-content text-center">
                            <div class="mb-1"><img src="assets/img/icon/192x192.png" alt="image" class="imaged w64 mb-2">
                            </div>
                            <div>
                                Install <strong>Finapp</strong> on your iPhone's home screen.
                            </div>
                            <div>
                                Tap <ion-icon name="share-outline"></ion-icon> and Add to homescreen.
                            </div>
                            <div class="mt-2">
                                <button class="btn btn-primary btn-block" data-bs-dismiss="modal">CLOSE</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>-->
        <!-- * iOS Add to Home Action Sheet -->

        <!-- Android Add to Home Action Sheet -->
<!--        <div class="modal inset fade action-sheet android-add-to-home" id="android-add-to-home-screen" tabindex="-1"
            role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Add to Home Screen</h5>
                        <a href="#" class="close-button" data-bs-dismiss="modal">
                            <ion-icon name="close"></ion-icon>
                        </a>
                    </div>
                    <div class="modal-body">
                        <div class="action-sheet-content text-center">
                            <div class="mb-1">
                                <img src="assets/img/icon/192x192.png" alt="image" class="imaged w64 mb-2">
                            </div>
                            <div>
                                Install <strong>Finapp</strong> on your Android's home screen.
                            </div>
                            <div>
                                Tap <ion-icon name="ellipsis-vertical"></ion-icon> and Add to homescreen.
                            </div>
                            <div class="mt-2">
                                <button class="btn btn-primary btn-block" data-bs-dismiss="modal">CLOSE</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>-->
        <!-- * Android Add to Home Action Sheet -->

        <!-- app footer -->
        <div class="appFooter">
            <div class="footer-title">
                Copyright © PayG Wallet
            </div>
            Todos los derechos reservados.
        </div>
        <!-- * app footer -->
    </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
    components: {

    },
    props: {
        active: Number,
        title: Boolean,
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo',
            AuthIsLogged: 'AuthIsLogged',
        })
    },
    mounted() {

        setTimeout(function (){
            const backdrop = document.getElementsByClassName("modal-backdrop")[0];
            if (backdrop) {
                backdrop.remove();
                document.body.style.overflow = 'auto';
                document.body.style.paddingRight = 'auto';
            }
        }, 300);
    },
    unmounted() {
    },
    methods: {
        logout() {
            this.$store.dispatch('AuthLogout');
            window.location.href = '/signin';
        }
    }
};
</script>
