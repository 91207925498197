<template>
    <layout :active="1" :title="true">

        <!-- App Header -->
        <div class="appHeader">
            <div class="left">
                <a @click="$router.back()" class="headerButton goBack">
                    <i class="fa-solid fa-chevron-left"></i>
                </a>
            </div>
            <div class="pageTitle">
                Mis tarjetas
            </div>
            <div class="right">
                <a ref="addNewCardButton" class="headerButton" data-bs-toggle="modal" data-bs-target="#addCardActionSheet" @click="clearForm">
                    <i class="fa-solid fa-plus"></i>
                </a>
            </div>
        </div>
        <!-- * App Header -->

        <!-- Add Card Action Sheet -->
        <div class="modal fade action-sheet" id="addCardActionSheet" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Agregar método de pago</h5>
                    </div>
                    <div class="modal-body">
                        <div class="action-sheet-content">
                            <form autocomplete="off" method="post" action="" name="addcredditcardpaygwallet">
                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="cardnumber1">Número de tarjeta</label>
                                        <input type="tel" @input="changeNumber" class="form-control" placeholder="Número de tarjeta" :value="addMethodNumber" :maxlength="cardNumberMaxLength" data-card-field autocomplete="off"/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-7">
                                        <div class="form-group basic">
                                            <div class="input-wrapper">
                                                <label class="label">Fecha de expiración</label>
                                                <div class="row">
                                                    <div class="col-4">
                                                        <select class="form-control custom-select" id="exp-month" v-model="addMethodMonth" data-card-field autocomplete="off">
                                                            <option value="" disabled hidden class="text-muted">Mes</option>
                                                            <option :value="n < 10 ? '0' + n : n" v-for="n in 12" :disabled="n < minCardMonth" :key="n">{{ generateMonthValue(n) }}</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-6">
                                                        <select class="form-control custom-select" id="exp-year" v-model="addMethodYear" data-card-field autocomplete="off">
                                                            <option value="" disabled hidden class="text-muted">Año</option>
                                                            <option :value="$index + minCardYear" v-for="(n, $index) in 12" v-bind:key="n">{{ $index + minCardYear }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-5">
                                        <div class="form-group basic">
                                            <div class="input-wrapper">
                                                <label class="label" for="cardcvv" data-card-field>
                                                    CVV
                                                    <a class="text-warning" @click="cvvQuestion">
                                                        <i class="fa-solid fa-question-circle"></i>
                                                    </a>
                                                </label>
                                                <input type="tel" id="cardcvv" class="form-control" placeholder="3 o 4 dígitos" v-model="addMethodCvv" data-card-field autocomplete="off" maxlength="4" pattern="[0-9]*" inputmode="numeric">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="text-center">
                                            <span class="validationErrorBig">{{ validationMsg }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group basic mt-2">
                                    <button type="button" class="btn btn-primary btn-block btn-lg" @click="addMethod">Agregar método de pago</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * Add Card Action Sheet -->

        <!-- App Capsule -->
        <div id="appCapsule">

            <div class="section mt-2">
                <!-- card block -->
                <div v-for="item in payMethods" class="card-block mb-2" :key="item.identifier">
                    <pay-card :item="item" @delete="getMethods" @update="getMethods" show-controls="1"></pay-card>
                </div>
                <!-- * card block -->
                <div v-if="Object.keys(payMethods).length === 0" class="text-center text-muted p-4">
                    Sin métodos de pago
                    <div class="mt-2" @click="getMethods">
                        <i class="fa-solid fa-refresh cursor-pointer"></i> &nbsp; Refrescar
                    </div>
                    <hr>
                </div>
            </div>
        </div>
        <!-- * App Capsule -->

    </layout>
</template>

<script>
import Layout from "src/modules/dashboard/components/Layout";
import payCard from "src/modules/wallet/components/Card";
import API from "../../../core/Api";
import {CONFIG} from "src/config";
import Tools from "src/core/Tools";

export default {
    name: "Dashboard",
    components: {
        //TimelineChart,
        Layout,
        payCard,
        //Toster,
    },
    data() {
        return {
            payMethods: {},
            addMethodNumber: null,
            addMethodYear: null,
            addMethodMonth: null,
            addMethodCvv: null,

            validationMsg: "",
            minCardYear: new Date().getFullYear(),
            cardNumberMaxLength: 19,
        };
    },
    watch: {
        addMethodNumber: function (value){
            this.validationMsg = '';
        },
        addMethodYear: function (value){
            this.validationMsg = '';
        },
        addMethodMonth: function (value){
            this.validationMsg = '';
        },
    },
    computed: {
        minCardMonth() {
            if (this.addMethodYear === this.minCardYear) return new Date().getMonth() + 1
            return 1
        },
    },
    mounted() {
        const self = this;
        this.getMethods();

        setTimeout(function (){
            if (window.location.hash === '#add') {
                self.$refs.addNewCardButton.click()
            }
        }, 500);
    },
    methods: {
        clearForm(n) {
            this.addMethodNumber = '';
            this.addMethodYear = '';
            this.addMethodMonth = '';
            this.addMethodCvv = '';
            this.validationMsg = '';
        },
        generateMonthValue(n) {
            return n < 10 ? `0${n}` : n
        },
        changeNumber(e) {
            this.addMethodNumber = e.target.value
            let value = this.addMethodNumber.replace(/\D/g, '')
            // american express, 15 digits
            if ((/^3[47]\d{0,13}$/).test(value)) {
                this.addMethodNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ')
                this.cardNumberMaxLength = 17
            }
            else if ((/^3(?:0[0-5]|[68]\d)\d{0,11}$/).test(value)) { // diner's club, 14 digits
                this.addMethodNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ')
                this.cardNumberMaxLength = 16
            }
            else if ((/^\d{0,16}$/).test(value)) { // regular cc number, 16 digits
                this.addMethodNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{4})/, '$1 $2 ').replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ')
                this.cardNumberMaxLength = 19
            }
            // eslint-disable-next-line eqeqeq
            if (e.inputType == 'deleteContentBackward') {
                let lastChar = this.addMethodNumber.substring(this.addMethodNumber.length, this.addMethodNumber.length - 1)
                // eslint-disable-next-line eqeqeq
                if (lastChar == ' ') {
                    this.addMethodNumber = this.addMethodNumber.substring(0, this.addMethodNumber.length - 1)
                }
            }
        },
        getMethods() {
            const self = this;
            API.send('GET', 'payments/paywallet/payment-method/get-all', {},
            function (response) {
                if (response.status) {
                    self.payMethods = response.data;
                }
            },
            function (response) {
                //self.invalidLink = true;
                API.showErrorAlert(response.msg);
            });
        },
        addMethod() {

            this.validationMsg = '';

            let number = this.addMethodNumber.replace(/ /g, '');

            let sum = 0
            for (var i = 0; i < number.length; i++) {
                let intVal = parseInt(number.substr(i, 1))
                if (i % 2 === 0) {
                    intVal *= 2
                    if (intVal > 9) {
                        intVal = 1 + (intVal % 10)
                    }
                }
                sum += intVal
            }

            if (sum % 10 !== 0 || number === '') {
                this.validationMsg = 'Número de tarjeta inválido';
                return false;
            }

            const self = this;
            API.send('POST', 'payments/paywallet/payment-method/add', {
                    card_number: number,
                    card_exp_date: self.addMethodMonth+'/'+self.addMethodYear.toString().substr(2),
                    card_cvv: self.addMethodCvv,
                },
                function (response) {
                    if (response.status) {
                        API.showSuccessAlert('', response.msg, 0);
                        Tools.closeBoostrapModal('#addCardActionSheet');
                        self.getMethods();
                    }
                    else {
                        API.showErrorNotify(response.msg);
                    }
                },
                function (response) {
                    //self.invalidLink = true;
                    API.showErrorNotify(response.msg);
                });
        },
        cvvQuestion() {
            API.showSuccessAlert('Código de seguridad', 'Puedes encontrar el código de seguridad atrás de tu tarjeta, dependiendo del banco, pueden ser tres o cuatro dígitos', 0);
        }
    },
};
</script>
