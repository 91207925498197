<template>
    <div>

        <div id="loader" v-if="coreGetPreloaderStatus">
            <div class="position-relative text-center">
                <img src="staticAssets/PayGuardian.png" alt="cargando" class="logo"/><br>
                <img src="staticAssets/pygwloading.svg" alt="icon" class="movement"/>
            </div>
        </div>

        <!-- App Capsule -->
        <div id="appCapsule">

            <div class="section mt-2 text-center">
                <h1>
                    <img class="logo" src="staticAssets/PayGuardian.png" alt="PayGuardian">
                </h1>
                <h3 class="mt-5 text-secondary">Creación de cuenta</h3>
            </div>
            <div class="section mb-5 p-2">
                <form method="post" @submit.prevent="formSubmit">
                    <div class="card">
                        <div class="card-body">
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="email1">Correo electrónico</label>
                                    <input type="email" class="form-control" placeholder="Tu correo electrónico" v-model="email" name="email">
                                    <span class="validationError">{{ Valid.email }}</span>
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="email1">Nombres</label>
                                    <input type="text" class="form-control" placeholder="Tu nombre" v-model="nombres" name="name">
                                    <span class="validationError">{{ Valid.nombres }}</span>
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="email1">Apellidos</label>
                                    <input type="text" class="form-control" placeholder="Tu nombre" v-model="apellidos" name="name">
                                    <span class="validationError">{{ Valid.apellidos }}</span>
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="password1">Contraseña</label>
                                    <input type="password" class="form-control" autocomplete="off" placeholder="Escribe una contraseña" v-model="password" name="password">
                                    <span class="validationError">{{ Valid.password }}</span>
                                    <span class="validationError" v-if="passwordStrengthMsg" :style="'color:' + passwordStrengthColor">{{ passwordStrengthMsg }}</span>
                                </div>
                            </div>
                            <div class="custom-control custom-checkbox mt-2 mb-1">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="customCheckb1" v-model="termsConditions" value="1">
                                    <label class="form-check-label" for="customCheckb1">
                                        Acepto los <a href="https://onlinedocs.bit.ai/rdc/iZkwAFNZf0EzqwVV" target="_blank" class="text-warning">términos y condiciones</a>
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="form-links mt-2">
                        <div>
                            <router-link to="signing" class="text-secondary">Ya tengo cuenta, iniciar sesión</router-link>
                        </div>
                    </div>

                    <div class="mt-5">
                        <button type="submit" class="btn btn-primary btn-block btn-lg">Crear mi cuenta</button>
                    </div>

                </form>
            </div>

        </div>
        <!-- * App Capsule -->
    </div>
</template>

<script>

import {mapMutations} from "vuex";
import {mapGetters} from "vuex";
import {CONFIG} from 'src/config';
import {store} from 'src/store';

import DataValidation from "src/core/DataValidation";
//import controlPanelStyle from "src/assets/controlPanel/css/style.lazy.css";
//import controlPanelPopupStyle from "src/assets/controlPanel/css/popup.lazy.css";
/*import publicStore from "src/assets/publicStore/css/theme.lazy.css";
import componentsStyleGlobal from "src/assets/controlPanel/css/Components.lazy.css";*/
import Tools from "src/core/Tools";
import API from "src/core/Api";
import localDisk from "src/localDisk";

export default {
    name: "Signup",
    components: {},
    props: {
        fastLogin: {
            default: false
        },
        showing: {
            default: 'signing'
        }
    },
    data() {
        return {
            Valid: {},
            email: "",
            nombres: "",
            apellidos: "",
            password: "",
            msg: "",
            termsConditions: 0,
            passwordStrength: 0,
            passwordStrengthMsg: false,
            passwordStrengthColor: false
        };
    },
    watch: {
        password: function (value) {
            //console.log(value);
            this.CheckPasswordStrength(value);
        }
    },
    computed: {
        ...mapGetters({
            coreGetPreloaderStatus: 'coreGetPreloaderStatus',
        })
    },
    methods: {
        ...mapGetters(["AuthIsLogged", "GetDeviceFingerprint"]),
        ...mapMutations(["AuthSetToken"]),
        formSubmit() {

            const self = this;
            const fp = self.GetDeviceFingerprint();

            const validation = new DataValidation()
            validation.email('email', this.email, 'Correo electrónico inválido');
            validation.required('password', this.password, 'Debes ingresar una contraseña');
            validation.required('nombres', this.nombres, 'Debes ingresar tu nombre');
            validation.required('apellidos', this.apellidos, 'Debes ingresar tu apellido');

            this.Valid = validation.validate(function () {

                if (!self.termsConditions) {
                    API.showErrorNotify("Para utilizar PayGuardian debes aceptar los términos y condiciones");
                    return false;
                }

                if (self.passwordStrength < 4) {
                    API.showErrorNotify("Ingresa una contraseña más fuerte");
                    return false;
                }

                store.dispatch('coreShowLoading');

                fetch(CONFIG.getWsUrl('payguardian/register'), {
                    method: "POST",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        "x-pg-fp": fp.f,
                        "x-pg-n": fp.n,
                        "x-pg-ua": fp.ua,
                    }),
                    body: JSON.stringify({
                        email: self.email,
                        password: self.password,
                        nombres: self.nombres,
                        apellidos: self.apellidos,
                    }),
                })
                    .then(response => response.json())
                    .then(data => {
                        if (typeof data.status !== 'undefined') {
                            if (data.status) {
                                self.AuthSetToken(data.data.token);
                                setTimeout(function () {
                                    store.dispatch('coreHideLoading');
                                    if (self.AuthIsLogged()) {

                                        const GoUrl = localDisk.read('PyGwalletGoUrl');
                                        if (GoUrl && GoUrl !== '') {
                                            localDisk.write('PyGwalletGoUrl', null);
                                            window.location.href = GoUrl;
                                        }
                                        else {
                                            self.$router.push('/dashboard');
                                        }
                                    }
                                }, 300);
                            }
                            else {
                                self.msg = data.msg;
                                store.dispatch('coreHideLoading');
                            }
                        }
                    })
                    .catch(() => {
                        store.dispatch('coreHideLoading');
                        self.msg = 'Error al registrarse, por favor intente de nuevo';
                    });
            });
        },
        CheckPasswordStrength(password) {

            //Regular Expressions
            const regex = [];
            regex.push("[A-Z]"); //For Uppercase Alphabet
            regex.push("[a-z]"); //For Lowercase Alphabet
            regex.push("[0-9]"); //For Numeric Digits
            regex.push("[$@$!%*#?&-.;=+]"); //For Special Characters

            let passed = 0;

            //Display of Status
            let color = "";
            let passwordStrength = "";

            //Validation for each Regular Expression
            for (var i = 0; i < regex.length; i++) {
                if ((new RegExp(regex[i])).test(password)) {
                    passed++;
                }

                if (password.length > 8 && passed > 2) {
                    passed++;
                }

                switch (passed) {
                    case 0:
                        break;
                    case 1:
                        passwordStrength = "La contraseña es débil";
                        color = "#d72d2d";
                        break;
                    case 2:
                        passwordStrength = "La contraseña es débil";
                        color = "darkorange";
                        break;
                    case 3:
                        break;
                    case 4:
                        passwordStrength = "La contraseña es fuerte";
                        color = "Green";
                        break;
                    case 5:
                        passwordStrength = "La contraseña es muy fuerte";
                        color = "darkgreen";
                        break;
                }
            }

            //Validation for Length of Password
            if (password.length < 8) {
                passwordStrength = "La contraseña debe tener 8 caracteres como mínimo";
                color = "#d72d2d";
            }
            this.passwordStrength = passed;
            this.passwordStrengthMsg = passwordStrength;
            this.passwordStrengthColor = color;
        }
    },
};
</script>
