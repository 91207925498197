/*export const CONFIG = {
    apiUrl: 'http://localhost/anysubscription_backend/public',
    getWsUrl: function (endpoint) {
        return 'http://localhost/anysubscription_backend/public/' + endpoint
    },
    localStorageDisk: 'AnySubscriptionDisk'
};*/
export const CONFIG = {
    defaultLang: 'es',
    apiUrl: 'https://api.anysubscriptions.com',
    getWsUrl: function (endpoint) {
        return 'https://api.anysubscriptions.com/' + endpoint
    },
    localStorageDisk: 'PayGWallet',
    oneSignalApp: '1bb85f6e-63ef-4ac8-9f24-577937d23155',
    PayGWalletUrl: 'https://pygwallet.com',
};
