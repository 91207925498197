<template>
    <div>

        <div id="loader" v-if="coreGetPreloaderStatus">
            <div class="position-relative text-center">
                <img src="staticAssets/PayGuardian.png" alt="cargando" class="logo"/><br>
                <img src="staticAssets/pygwloading.svg" alt="icon" class="movement"/>
            </div>
        </div>

        <!-- App Capsule -->
        <div id="appCapsule">

            <div class="section mt-5 text-center">
                <h1>
                    <img class="logo" src="staticAssets/PayGuardian.png" alt="PayGuardian">
                </h1>
                <h3 class="mt-5 text-secondary">Inicio de sesión</h3>
            </div>
            <div class="section mb-5 p-2">
                <form method="post" @submit.prevent="formSubmit">
                    <div class="card">
                        <div class="card-body pb-1">
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="email1">Correo electrónico</label>
                                    <input type="email" class="form-control" id="email1" placeholder="Tu correo electrónico" v-model="email">
                                    <i class="clear-input fa-solid fa-times-circle"></i>
                                    <span class="validationError">{{ Valid.email }}</span>
                                </div>
                            </div>

                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="password1">Contraseña</label>
                                    <input type="password" class="form-control" id="password1" autocomplete="off" placeholder="Tu contraseña" v-model="password">
                                    <i class="clear-input fa-solid fa-times-circle"></i>
                                    <span class="validationError">{{ Valid.password }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-links mt-2">
                        <div>
                            <router-link to="signup" class="text-secondary">Registrarme</router-link>
                        </div>
                        <div>
                            <router-link to="/reset/password" class="text-secondary">Olvidé mi contraseña</router-link>
                        </div>
                    </div>
                    <div class="mt-5">
                        <button type="submit" class="btn btn-primary btn-block btn-lg">Iniciar sesión</button>
                    </div>

                </form>
            </div>

        </div>
        <!-- * App Capsule -->
    </div>
</template>

<script>

import {mapMutations} from "vuex";
import {mapGetters} from "vuex";
import {CONFIG} from 'src/config';
import {store} from '../../../store';
//import controlPanelPopupStyle from "../../../assets/controlPanel/css/popup.lazy.css";
//import controlPanelStyle from "../../../assets/controlPanel/css/style.lazy.css";
//import publicStore from "src/assets/publicStore/css/theme.lazy.css";
//import componentsStyleGlobal from "../../../assets/controlPanel/css/Components.lazy.css";
import API from "src/core/Api";

import DataValidation from "src/core/DataValidation";
import localDisk from "src/localDisk";

export default {
    name: "Signin",
    components: {},
    props: {
    },
    data() {
        return {
            Valid: {},
            email: "",
            password: "",
            checkbox: "",
            msg: "",
        };
    },
    beforeCreate() {
        //controlPanelStyle.use();
        //controlPanelPopupStyle.use();
        /*publicStore.use();
        componentsStyleGlobal.use();*/
    },
    mounted() {
        //BaseScript.loadSite();
    },
    unmounted() {
        //controlPanelStyle.unuse();
        //controlPanelPopupStyle.unuse();
        /*publicStore.unuse();
        componentsStyleGlobal.unuse();*/
    },
    computed: {
        ...mapGetters({
            coreGetPreloaderStatus: 'coreGetPreloaderStatus',
        })
    },
    methods: {
        ...mapGetters(["AuthIsLogged", "GetDeviceFingerprint", "GetGoUrl"]),
        ...mapMutations(["AuthSetToken", "SetUserInfo"]),
        formSubmit() {

            const self = this;

            const validation = new DataValidation()
            validation.email('email', this.email, 'Ingrese un email válido');
            validation.required('password', this.password, 'Debe ingresar una contraseña');

            this.Valid = validation.validate(function () {

                const fp = self.GetDeviceFingerprint();
                store.dispatch('coreShowLoading');

                fetch(CONFIG.getWsUrl('payguardian/authorize'), {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "x-pg-fp": fp.f,
                        "x-pg-n": fp.n,
                        "x-pg-ua": fp.ua,
                    },
                    body: JSON.stringify({
                        email: self.email,
                        password: self.password,
                    }),
                })
                    .then(response => response.json())
                    .then(data => {
                        store.dispatch('coreHideLoading');
                        if (typeof data.status !== 'undefined') {
                            if (data.status) {
                                self.AuthSetToken(data.data.token);
                                if (self.AuthIsLogged()) {

                                    const GoUrl = localDisk.read('PyGwalletGoUrl');
                                    if (GoUrl && GoUrl !== '') {
                                        localDisk.write('PyGwalletGoUrl', null);
                                        window.location.href = GoUrl;
                                    }
                                    else {
                                        self.$router.push('/dashboard');
                                    }
                                }
                            }
                            else {
                                API.showErrorNotify(data.msg);
                            }
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        store.dispatch('coreHideLoading');
                        API.showErrorNotify('Error de conexión, por favor intente de nuevo');
                    });
            });
        },
    },
};
</script>
