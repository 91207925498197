import localDisk from 'src/localDisk';
import {CONFIG} from "src/config";
import API from "src/core/Api";
import {store} from "src/store";

export const authState = {
    state: {
        token: localDisk.read('authToken'),
        userInfo: localDisk.read('authUserInfo'),
    },
    mutations: {
        AuthSetToken(state, data) {
            if ( data ) {
                state.token = data
                localDisk.write('authToken', data)
            }
            else {
                localDisk.write('authToken', null)
            }
        },
        SetUserInfo(state, data) {
            if ( data ) {
                state.userInfo = data
                localDisk.write('authUserInfo', data)
            }
            else {
                localDisk.write('authUserInfo', null);
            }
        },
    },
    getters: {
        AuthIsLogged: state => {
            return !!state.token;
        },
        AuthGetToken: state => {
            return state.token;
        },
        AuthGetUserInfo: state => {
            if (state.userInfo) {
                return state.userInfo;
            }
            else {
                return {};
            }
        },
    },
    actions: {
        AuthValidateToken: ({commit, getters}, payload) => {

            const token = getters.AuthGetToken;
            const fp = getters.GetDeviceFingerprint;

            if (token) {

                //store.dispatch('coreShowLoading');

                fetch(CONFIG.getWsUrl('payguardian/auth_check'), {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "x-pg-fp": fp.f,
                        "x-pg-n": fp.n,
                        "x-pg-ua": fp.ua,
                    },
                    body: JSON.stringify({
                        token: getters.AuthGetToken,
                    }),
                })
                    .then(response => response.json())
                    .then(data => {

                        if (typeof data.status !== 'undefined') {

                            // si el token no es valido, deslogueo
                            if (data.status === 0 || !data.status) {
                                commit('AuthSetToken', false);
                                if (typeof payload.routerGuard === 'function') payload.routerGuard('signin');
                            }
                            else {
                                // si es valido, guardo la info y continuo bien
                                commit('SetUserInfo', data.data);
                                if (typeof payload.callback === 'function') payload.callback(data.data);
                            }
                        }
                        else {
                            // deslogueo
                            commit('AuthSetToken', false);
                            if (typeof payload.routerGuard === 'function') payload.routerGuard('signin');
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        commit('AuthSetToken', false);
                        if (typeof payload.routerGuard === 'function') payload.routerGuard('signin');
                    });
            }
            else {
                commit('AuthSetToken', false);
                if (typeof payload.routerGuard === 'function') payload.routerGuard('signin');
            }
        },
        AuthLogout: () => {
            localDisk.clearDisk();
        },
        AuthRefreshToken: ({commit}) => {
            API.send('POST','users/auth_refresh', {},
            function (response) {

                    if (typeof response.data.token !== 'undefined') {
                        commit("AuthSetToken", response.data.token);
                        commit('SetUserInfo', response.data.user);
                    }
                },
                function (data) {
                    API.showErrorAlert(data.msg);
                }, {
                    disableLoading: true
                })
        },
    }
}
